
export const ORDER_STATUSES = [
  "All statuses",
  "On the way",
  "Delivered",
  "Rejected",
  "On the way back",
  "Delivered back",
]

export const SORT_BY_TIME = {
  "Last month": "30",
  "Last 60 days": "60",
  "Last 90 days": "90",
  "Last year": "365"
}

export const DEFAULT_PRICE_OBJECT = {
  total_paid_in_store: 0,
  total_to_refund: 0,
  total_to_pay_now: 0,
};



export const TEST_ORDERS = [
  {
    "order_number": "1",
    "order_id": "237",
    "retailer_name": "Target",
    "retailer_description": "Big chain",
    "retailer_website": "www.target.com",
    "order_type": "exchange",
    "order_created_at": "05/19/2022, 23:18:23 UTC",
    "order_updated_at": "05/23/2022, 15:49:16 UTC",
    "order_details": "Please deliver ASAP!!!!",
    "retailer_cost": "40.00",
    "order_assigned_at": null,
    "order_picked_at": null,
    "picked_photo": null,
    "order_delivered_at": null,
    "delivered_photo": null,
    "order_return_picked_at": null,
    "return_picked_photo": null,
    "order_returned_at": null,
    "returned_photo": null,
    "order_completed_at": null,
    "order_rejected_at": "05/23/2022, 15:49:16 UTC",
    "failure_reason": "TASK_DELETED",
    "pickup_address1": "37337 Bunchberry Ln",
    "pickup_address2": null,
    "pickup_city": "Murrieta",
    "pickup_state": "CA",
    "pickup_zip": "92562",
    "pickup_country": "USA",
    "pickup_location_details": "Huge house",
    "dropoff_address1": "85 Bianco",
    "dropoff_address2": null,
    "dropoff_city": "Irvine",
    "dropoff_state": "CA",
    "dropoff_zip": "92618",
    "dropoff_country": "USA",
    "dropoff_location_details": "Big house",
    "return_pickup_address1": "85 Bianco",
    "return_pickup_address2": null,
    "return_pickup_city": "Irvine",
    "return_pickup_state": "CA",
    "return_pickup_zip": "92618",
    "return_pickup_country": "USA",
    "return_pickup_location_details": "Big house",
    "return_address1": "37337 Bunchberry Ln",
    "return_address2": null,
    "return_city": "Murrieta",
    "return_state": "CA",
    "return_zip": "92562",
    "return_country": "USA",
    "return_location_details": "Huge house",
    "shopper_email": "hcodfwhekimfaikbfivhfaum@goflyy.com",
    "shopper_phone": "+19802002545",
    "shopper_first_name": "Leonardo",
    "shopper_middle_name": null,
    "shopper_last_name": "Kotovich",
    "shopper_title": null,
    "shopper_dob": null,
    "shopper_sex": null,
    "shopper_notes": null,
    "shopper_social_fb": "https://facebooc.com/page",
    "shopper_social_ln": "https://linkedin.com/page",
    "shopper_social_in": "@instagram_account",
    "shopper_social_tw": "@twitter_account",
    "list_of_products": [
      {
        "product_number": "1",
        "product_id": "402",
        "product_name": "Guchiqeas",
        "product_description": "Coolasd",
        "product_price": "233.0",
        "product_weight": "12",
        "product_dimentions_height": "2",
        "product_dimentions_width": "3",
        "product_dimentions_length": "4",
        "picture_url": "https://cdn.pixabay.com/photo/2020/08/12/16/04/clothes-5483040_1280.jpg",
        "tryout": "Y",
        "custom_id": "873465873687465837678",
        "sold_at": null
      },
      {
        "product_number": "2",
        "product_id": "401",
        "product_name": "Nike Snickers",
        "product_description": "Size 34",
        "product_price": "256.0",
        "product_weight": "4",
        "product_dimentions_height": "5",
        "product_dimentions_width": "6",
        "product_dimentions_length": "7",
        "picture_url": "https://i.guim.co.uk/img/media/4d01c8e642b6cace4de963859631b55e6bbd1b49/0_30_4368_2621/master/4368.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=6d81f28273e9108fbc0df4a206114c47",
        "tryout": null,
        "custom_id": "888884773847586876",
        "sold_at": null
      }
    ],
    "shipping_label_url": "https://api.goflyy.com/v1/retailer/getShippingLabel/mr.klimanov@gmail.com/237",
  }]

export
const statusColor = (status: string) => {
  switch (status) {
    case 'Delivered': {
      return {
        backgroundColor: `#0CCF71`,
        color: `#fff`,
      }
    }
    case 'Delivered. Waiting for return.': {
      return {
        backgroundColor: `#0CCF71`,
        color: `#fff`,
      }
    }
    case 'On the way to shopper': {
      return {
        backgroundColor: `#FDDD16`,
        color: `#2D3033`,
      }
    }
    case 'Returned': {
      return {
        backgroundColor: `#2D3033`,
        color: `#fff`
      }
    }
    case 'On the way back': {
      return {
        backgroundColor: `#AB56FF`,
        color: `#fff`
      }
    }
    default: {
      return {
        backgroundColor: `#FDDD16`,
        color: `#2D3033`,
      }
    }
  }
};