import { customColors } from '../../../theme/palette';

export default {
  landingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%"
  },
  contentBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "5%",
    width: "90%",
    ['@media (max-width:1200px)']: {
      width: "96%",
    },
    ['@media (max-width:900px)']: {
      width: "98%",
    },
  },
  specialContentBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "5%",
    width: "100%",
  },
  contentBoxLeft: {
    paddingLeft: "4.5%",
    ['@media (max-width:1600px)']: {
      paddingLeft: "0px"
    },
  },
  contentBoxRight: {
    paddingLeft: "10%",
    ['@media (max-width:1500px)']: {
      paddingLeft: "0px"
    },
  },
  contentPadding: {

  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    minHeight: "800px",
    minWidth: "25%",
    maxWidth: "75%",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  innerBoxWithoutPadding: {
    display: "flex",
    flexDirection: "column",
    minHeight: "700px",
    minWidth: "390px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  expBox: {
    height: "780px",
    backgroundPosition: '-50px -50px',
    backgroundSize: '2000px',
    backgroundRepeat: 'no-repeat',
    alignContent: "center",
    justifyContent: "flex-start",
    ['@media (max-width:1200px)']: {
      backgroundPosition: '-200px -50px',
    },
    ['@media (max-width:1000px)']: {
      backgroundPosition: '-300px -50px',
    },
    ['@media (max-width:900px)']: {
      backgroundPosition: '-400px -50px',
    },
    ['@media (max-width:800px)']: {
      backgroundSize: '1500px',
      backgroundPosition: '-450px 200px',
      alignContent: "flex-start"
    },
    ['@media (max-width:500px)']: {
      backgroundPosition: '-500px 200px',
    },
    ['@media (max-width:420px)']: {
      backgroundPosition: '-600px 200px',
    },

  },
  sidedContent: {
    width: "30%"
  },
  left: {

  },
  signUp: {
    color: customColors.black,
    backgroundColor: customColors.primary,
    borderRadius: "26px"
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    minWidth: "48px",
    minHeight: "48px"
  },
  assets: {
    backgroundColor: customColors.black,
    display: "flex",
    width: "48px",
    height: "54px",
    justifyContent: "center",
    alignItems: "center",
    color: customColors.primary,
    borderRadius: "100%",
    "&:hover": {
      backgroundColor: customColors.darkGray,
    }
  },
  defaultMargin: {
    marginBottom: "5%",
  },
  createAnAcc: {
    width: "250px",
    color: customColors.black,
    borderRadius: "10px",
    borderWidth: "3px",
    fontSize: "subtitle2",
    "&:hover": {
      borderWidth: "3px",
    }
  },
  whatWeDoBox: {
    display: "flex",
    flexDirection: "row",
    width: "65%",
    flexWrap: "no-wrap",
    alignItems: "center",
  },
  whatWeDoBoxTablet: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  iconBox: {
    width: "50px",
    backgroundColor: customColors.white,
    color: customColors.darkPurple,
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  smallerDoBox: {
    backgroundColor: customColors.darkPurple,
    color: customColors.white,
    height: "38%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "5%",
    borderRadius: "10px",
    width: "20%",
    minWidth: "140px",
    position: "relative",
    // "&:hover": {
    //   width: "22%",
    //   position: "absolute"
    // }
  },
  doBox: {
    border: `5px solid ${customColors.darkPurple}`,
    color: customColors.black,
    height: "46%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "5%",
    borderRadius: "10px",
    width: "25%",
  },
  retailBox: {
    backgroundColor: customColors.green,
    borderRadius: "7px",
    color: customColors.white,
    display: "flex",
    justifyContent: "center",
    height: "60%",
    width: "22%",
    minWidth: "220px",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "5%",
    ['@media (max-width:1280px)']: {
      marginLeft: "2%",
      width: "100%",
      height: "60%",
      marginBottom: "50px"
    },
    ['@media (max-width:933px)']: {
      marginLeft: "1%",
    },
  },
  ceoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  twoSidedPics: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "30px",
    ['@media (max-width:600px)']: {
      marginRight: "0px",
      justifyContent: "center"
    },
    ['@media (max-width:450px)']: {
      minWidth: "300px"
    },
  },
  faqBox: {
    backgroundColor: customColors.darkPurple,
    color: customColors.white,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "10%",
    maxHeight: "340px",
    maxWidth: "55%",
    borderRadius: "7px",
    flexDirection: "column"
  },
  faqTabletBox: {
    backgroundColor: customColors.darkPurple,
    color: customColors.white,
    padding: "5%",
    borderRadius: "12px",
    flexDirection: "column",
    width: "100%"
  },
  joinUsBox: {
    backgroundColor: customColors.darkPurple,
    color: customColors.white,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "12%",
    minHeight: "40%",
    maxWidth: "80%",
    borderRadius: "15px",
    flexDirection: "column",
    ['@media (max-width:1400px)']: {
      marginTop: "20px",
      width: "70%",
      maxWidth: "70%"
    },
  },
  testsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ['@media (max-width:900px)']: {
      minWidth: "100%",
      justifyContent: "center",
    },
  },
  sliderButtons: {
    borderRadius: "100%",
    backgroundColor: customColors.green,
    color: customColors.white,
    minWidth: "50px",
    minHeight: "50px",
    maxWidth: "50px",
    maxHeight: "50px",
    '&:hover': {
      backgroundColor: customColors.darkGreen,
    },
    ['@media (max-width:900px)']: {
      minWidth: "40px",
      minHeight: "40px",
      maxWidth: "40px",
      maxHeight: "40px",
      padding: "0px"
    },
  },
  reviewBox: {
    width: "470px",
    height: "350px",
    justifyContent: "space-between",
    flexDirection: "column",
    marginLeft: "10%",
    marginRight: "10%",
    ['@media (max-width:700px)']: {
      width: "350px",
    },
    ['@media (max-width:600px)']: {
      width: "300px",
      height: "500px"
    },
    ['@media (max-width:500px)']: {
      width: "200px",
    },
    ['@media (max-width:400px)']: {
      width: "100px",
    },
  },
  resizeH1: {
    ['@media (max-width:1300px)']: {
      fontSize: 48
    },
    ['@media (max-width:760px)']: {
      fontSize: 32
    },
  },
  resizeH2: {
    ['@media (max-width:1300px)']: {
      fontSize: 36
    },
    ['@media (max-width:760px)']: {
      fontSize: 24
    },
  },
  resizeH3: {
    ['@media (max-width:1300px)']: {
      fontSize: 16
    },
    ['@media (max-width:760px)']: {
      fontSize: 18
    },
  },
  bckGroundRect: {
    width: "784px",
    height: "593px",
    borderRadius: "300px",
    backgroundColor: customColors.primary,
    position: "absolute",
    zIndex: -1,
    ['@media (max-width:1500px)']: {
      display: "none"
    },
  },
  rectRight: {
    left: "85%",
  },
  rectLeft: {
    right: "85%"
  },
  aboutRects: {
    ['@media (max-width:1680px)']: {
      display: "none"
    },
  },
}