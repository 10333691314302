import { useEffect, useState } from 'react';
import { InitialUserState } from '../../../redux/user/constants';
import { Box, Button, CardMedia, MenuItem, TextField, Typography } from '@mui/material';
import landStyles from '../../LandingPage/src/styles';
import { customColors } from '../../../theme/palette';
import styles from '../../Dashboard/src/styles';
import StyledButton from '../../UI/StyledButton';
import { useSelector } from 'react-redux';
import Loader from '../../LandingPage/components/Loader';
import { useActions, useUser } from '../../../hooks/useHooks';
import { compareDraft } from '../../../utils/compare-draft';
import { FileService } from '../../../api/services/file-service';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MuiPhoneNumber from 'material-ui-phone-number';
import PhoneModal from './PhoneModal';
import { UserService } from '../../../api/services/user-service';
import { SignService } from '../../../api/services/sign-service';
import { AddressInfoKeys, ContactInfoKeys } from '../src/constants';


export default () => {
  const [user, setUser] = useState(InitialUserState);
  const [loading, setLoading] = useState(true);
  const [modalTrigger, setModalTrigger] = useState(false);
  const [isPhoneChanged, setPhoneChanged] = useState(false);
  const userDraft = useUser();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState({
    image: userDraft.avatarUrl,
    file: {},
  });
  const { getCurrentUser, updateUserDraft, updateUser, showErrorMessage, showSuccessMessage, clearUserDraft } = useActions();

  useEffect(() => {
    setSelectedFile((prevState: any) => ({ ...prevState, image: userDraft.userDraft.profile_pic }) );
  }, [userDraft.userDraft.profile_pic]);

  useEffect(() => {
    getCurrentUser()
      // @ts-ignore
      .then((res: any) => {
        setUser((prevState) => ({...prevState, ...res, email: localStorage.getItem('email')}));
        setLoading(false);
      }).catch((e: any) => {
        showErrorMessage();
      });
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      const newFields = compareDraft(user, userDraft.userDraft);
      const newFieldsKeys = Object.keys(newFields);
      const isContact = ContactInfoKeys.some((r: any) => newFieldsKeys.includes(r));
      const isAddress = AddressInfoKeys.some((r: any) => newFieldsKeys.includes(r));
      if (isContact) {
        newFields.first_name = userDraft.userDraft.first_name;
        newFields.last_name = userDraft.userDraft.last_name;
        newFields.sex = userDraft.userDraft.sex;
        if (newFields.dob) {
          newFields.dob = `${newFields.dob.getUTCFullYear()}-${newFields.dob.getUTCMonth() + 1}-${newFields.dob.getUTCDate() + 1}`;
        } else {
          newFields.dob = userDraft.userDraft.dob;
        }
        if (newFields.phone) {
          newFields.country_code = `+ 1`;
        }
      }
      if (isAddress) {
        newFields.address1 = userDraft.userDraft.address1;
        newFields.city = userDraft.userDraft.city;
        newFields.state = userDraft.userDraft.state;
        newFields.zip = userDraft.userDraft.zip;
        newFields.country = userDraft.userDraft.country;
      }
      if (
        Object.keys(newFields).length
      ) {
        updateUser(newFields);
      }
      if (selectedFile.image && selectedFile.file) {
        try {
          const resp = await FileService.uploadProfilePicture(selectedFile.file);
          if (resp.status !== "200") {
            showErrorMessage(`Profile picture failed.`);
          }
        } catch (e) {
          showErrorMessage(`Profile picture failed.`);
        }
        updateUser({ ...newFields });
      }
      if (newFields.phone && newFields.phone.length) {
        setModalTrigger(true);
      }
      setUser((prevState) => ({ ...prevState, ...newFields }));
      showSuccessMessage();
    } catch (e) {
      showErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = ({ target }: any) => {
    const [file] = target.files;
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedFile({
        image: reader.result,
        file: file,
      });
    };

    reader.readAsDataURL(file);
    target.value = '';
  };

  const handleFileRemove = () => {
    setSelectedFile({
      image: '',
      file: {},
    });
  };

  return (
    <form onSubmit={handleSubmit}>
    <PhoneModal modalTrigger={modalTrigger} setModalTrigger={setModalTrigger} phone={userDraft.userDraft.phone}  country_code={userDraft.userDraft.country_code}/>
    {loading ? <Loader /> :
    (<Box sx={{display: "flex", flexDirection: "row",
      ['@media (max-width:900px)']: {
        flexDirection: "column",
      },
    }}>
      <Box sx={{marginRight: "30px"}}>
      <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin}}>
        <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Contact information</Typography>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField required label="First Name" variant="outlined" type="required" sx={{...styles.input}} value={userDraft.userDraft.first_name}
                     onChange={e => updateUserDraft('first_name', e.target.value )}/>
          <TextField required label="Last Name" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.last_name}
                     onChange={e => updateUserDraft('last_name', e.target.value )}/>
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <MuiPhoneNumber defaultCountry={'us'}
                    sx={{...styles.input, justifyContent: "center"}}
                    excludeCountries={['ru', ]}
                    onlyCountries={['us']}
                    disableAreaCodes={true}
                    value={userDraft.userDraft.country_code ? (userDraft.userDraft.country_code + userDraft.userDraft.phone) : userDraft.userDraft.phone}
                    variant="outlined"
                     onChange={e => {
                       if (typeof e === 'string') {
                         const numbers = e.split(' (');
                         updateUserDraft('country_code', '+ 1');
                         const regionCode = numbers[1] ? numbers[1].split(') ') : ['', ''];
                         const phoneNumbers = regionCode[1] ? regionCode[1].split('-') : ['', ''];
                         updateUserDraft('phone', regionCode[0] + phoneNumbers[0] + phoneNumbers[1]);
                       }
                     }}/>
          <TextField required label="E-mail address" variant="outlined" inputProps={{ readOnly: true }} sx={{...styles.input, marginLeft: "20px"}} value={localStorage.getItem('email')}
                     // onChange={e => updateUserDraft('email', e.target.value )}
          />
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="Date of birth"
            value={userDraft.userDraft.dob}
            onChange={(newValue: any) => {
              updateUserDraft('dob', newValue);
            }}
            renderInput={(params) => <TextField sx={{...styles.input}} {...params} helperText={null} />}
          />
          </LocalizationProvider>
          <TextField select label="Gender" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.sex}
                     onChange={e => updateUserDraft('sex', e.target.value )}>
            <MenuItem key={'male'} value={'male'} sx={{fontSize: 16}}>
              Male
            </MenuItem>
            <MenuItem key={'female'} value={'female'} sx={{fontSize: 16}}>
              Female
            </MenuItem>
            <MenuItem key={undefined} value={undefined} sx={{fontSize: 16}}>
              Prefer not to say
            </MenuItem>
          </TextField>
        </Box>
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin}}>
        <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Address information</Typography>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField required label="Address" variant="outlined" type="required" sx={{...styles.input}} value={userDraft.userDraft.address1}
                     onChange={e => updateUserDraft('address1', e.target.value )}/>
          <TextField label="Second Address" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.address2}
                     onChange={e => updateUserDraft('address2', e.target.value )}/>
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField required label="City" variant="outlined" type="required" sx={{...styles.input}} value={userDraft.userDraft.city}
                     onChange={e => updateUserDraft('city', e.target.value )}/>
          <TextField required label="State" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.state}
                     onChange={e => updateUserDraft('state', e.target.value)}/>
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField required label="ZIP" type="number" variant="outlined" sx={{...styles.input}} value={userDraft.userDraft.zip}
                     onChange={e => updateUserDraft('zip', e.target.value )} inputProps={
            { readOnly: !!user.zip, }
          }/>
          <TextField required label="Country" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.country}
                     onChange={e => updateUserDraft('country', e.target.value )}/>
        </Box>
        <TextField fullWidth label="Details" variant="outlined" sx={{...styles.input}} value={userDraft.userDraft.address_details}
                   onChange={e => updateUserDraft('address_details', e.target.value)}/>
      </Box>
      <Box sx={{display: "flex", flexDirection: "row", maxWidth: "540px"}}>
        <StyledButton text="Save Changes" type="submit" sx={{...styles.buttons}} />
        {/*<Button variant="outlined" sx={{...styles.buttons, marginLeft: "20px", color: customColors.black, borderColor: "red"}}*/}
        {/*    onClick={async () => {*/}
        {/*      const email = localStorage.getItem('email');*/}
        {/*      // @ts-ignore*/}
        {/*      await SignService.logout(email);*/}
        {/*      clearUserDraft();*/}
        {/*      localStorage.clear();*/}
        {/*      navigate('/');*/}
        {/*    }}*/}
        {/*>*/}
        {/*  Logout*/}
        {/*</Button>*/}
      </Box>
    </Box>
    <Box sx={{}}>
      <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Avatar</Typography>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={(e) => {
          handleFileSelect(e);
        }}
      />
      <label htmlFor="raised-button-file">
        {!selectedFile.image ?
          <Button variant="outlined" component="span" sx={{
            borderStyle: "dashed",
            borderColor: customColors.blue,
            color: customColors.black,
            width: "260px",
            height: "160px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            ...styles.defaultMargin
          }}>
            <Typography sx={{ fontSize: 16 }}>Upload file</Typography>
            <Typography sx={{ fontSize: 16 }}>no more 10Mb</Typography>
          </Button>
          :
          <CardMedia
            sx={{
              width: "260px",
              height: "160px",
            }}
            image={selectedFile.image}
          />
        }
      </label>
    </Box>
    </Box>
    )}
  </form>
  )
}