const keys = {
  TEST_NAME: `testimonial_name`,
  TEST_TITLE: `testimonial_title`,
  TEST_MSG: `testimonial_msg`,
  TEST_PIC: `testimonial_pic`,
  TEST_DATE: `testimonial_date`,
  FAQ_QUEST: `faq_question`,
  FAQ_ANSWER: `faq_answer`,
  RETAIL_LOGO: `retail_partner_logo`,
  API_DOC: `api_doc`,
  API_DOC_VERSION: `api_doc_version`,
  API_DOC_VIDEO: `api_doc_video_guide`,
  SOCIAL_MEDIA: `social_`,
  TELEPHONE: `telephone`,
  APP_LINK: `_app_link`,
  TOS: `terms_of_service`,
  PP: `privacy_policy`,
  OG: `offer_agreement`,
}

interface IParsedData {
  testimonials: Record<string, unknown>[],
  faq: Record<string, unknown>[],
  retailers: string[],
  social: Record<string, unknown>,
  telephone: string,
  appLinks: Record<string, unknown>,
  docLinks: {
    TOS: string,
    PP: string,
    OG: string,
  },
}

export const LpDataParser = (data: any): IParsedData => {
  const result: IParsedData = {
    testimonials: [],
    faq: [],
    retailers: [],
    social: {},
    telephone: ``,
    appLinks: {},
    docLinks: {
      TOS: ``,
      PP: ``,
      OG: ``
    },
  }
  data.map((pieceOfShit: any) => {
    Object.entries(pieceOfShit).map(([key, value]: any) => {
      if (key.includes(keys.TEST_NAME)) {
        const test = {
          name: value,
          title: ``,
          message: ``,
          picture: ``,
          date: ``
        };
        const [,index] = key.split(keys.TEST_NAME);
        test.title = pieceOfShit[keys.TEST_TITLE + index] ? pieceOfShit[keys.TEST_TITLE + index] : ``;
        test.message = pieceOfShit[keys.TEST_MSG + index] ? pieceOfShit[keys.TEST_MSG + index] : ``;
        test.picture = pieceOfShit[keys.TEST_PIC + index] ? pieceOfShit[keys.TEST_PIC + index] : ``;
        test.date = pieceOfShit[keys.TEST_DATE + index] ? pieceOfShit[keys.TEST_DATE + index] : ``;
        if ((test.title || test.message) && test.date && test.name) {
          result.testimonials.push(test);
        }
      } else if (key.includes(keys.FAQ_QUEST)) {
        const faq = {
          question: value,
          answer: ``
        };
        const [,index] = key.split(keys.FAQ_QUEST);
        faq.answer = pieceOfShit[keys.FAQ_ANSWER + index] ? pieceOfShit[keys.FAQ_ANSWER + index] : ``;
        if (faq.question && faq.answer) {
          result.faq.push(faq);
        }
      } else if (key.includes(keys.RETAIL_LOGO)) {
        if (value) {
          result.retailers.push(<string>value);
        }
      } else if (key.includes(keys.SOCIAL_MEDIA)) {
        if (value) {
          result.social[key] = value;
        }
      } else if (key.includes(keys.TELEPHONE)) {
        if (value) {
          result.telephone = value;
        }
      } else if (key.includes(keys.APP_LINK)) {
        if (value) {
          result.appLinks[key] = value;
        }
      } else if (key.includes(keys.TOS)) {
        if (value) {
          result.docLinks.TOS = value;
        }
      } else if (key.includes(keys.PP)) {
        if (value) {
          result.docLinks.PP = value;
        }
      } else if (key.includes(keys.OG)) {
        if (value) {
          result.docLinks.OG = value;
        }
      }
    });
  });
  return result;
};