import { combineReducers } from 'redux';
import { AuthTypes } from './auth/types';
import userReducer from "./user/reducers";
import dashboardReducer from "./dashboard/reducers";
import notificationsReducer from "./notifications/reducers";
import supportReducer from "./support/reducers";
import snackbarReducer from "./snackbar/reducers";


const appReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  notifications: notificationsReducer,
  support: supportReducer,
  snackbar: snackbarReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === AuthTypes.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;