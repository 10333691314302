import { Box, CardMedia, MenuItem, Modal, TextField, Typography } from '@mui/material';
import styles from "../../Dashboard/src/styles";
import { customColors } from '../../../theme/palette';
import { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { UserService } from '../../../api/services/user-service';
import { useActions } from '../../../hooks/useHooks';
import StyledButton from '../../UI/StyledButton';

export default ({modalTrigger, setModalTrigger, currentCard}: any) => {
  const [cardDraft, updateCardDraft] = useState({
    ...currentCard,
    number: ``
  });
  const [expData, setExpData] = useState(new Date());
  const { getCurrentUser, showErrorMessage, showSuccessMessage } = useActions();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const email = localStorage.getItem('email');
      if (email) {
        const {status, message} = await UserService.addCard(email, cardDraft);
        if (status === "200" && message !== "wrong_card_information") {
          getCurrentUser();
          setModalTrigger(false);
          showSuccessMessage();
        } else {
          showErrorMessage();
        }
      }
    } catch (e) {
      showErrorMessage();
    }
  };
  return (
    <Modal
      open={modalTrigger}
      onClose={() => setModalTrigger(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
        <Box sx={{ ...styles.modal, justifyContent: "center", minHeight: "300px"}}>
          <Box sx={{...styles.insideModalBox, minHeight: "90%", }}>
            <form onSubmit={handleSubmit} >
            <Typography variant="h2" sx={{...styles.defaultMargin, textAlign: "center"}}>
              Card
            </Typography>
            <TextField required fullWidth label="Card Number" variant="outlined" type="number" sx={{ ...styles.defaultMargin, ...styles.input}} value={cardDraft.number}
                       onChange={e => updateCardDraft((prevState: any) => ({...prevState, number: e.target.value }))}/>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}} >
              <TextField required label="CVC code" variant="outlined" type="number" sx={{...styles.input, ...styles.cardInput}} value={cardDraft.cvc}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, cvc: e.target.value }))}/>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  openTo="year"
                  views={['year', 'month']}
                  label="Expiration date"
                  minDate={new Date()}
                  maxDate={new Date('2050-06-01')}
                  value={expData}
                  onChange={(newValue) => {
                    if (newValue) {
                      updateCardDraft((prevState: any) => ({...prevState, exp_month: newValue.getUTCMonth() + 1 }));
                      updateCardDraft((prevState: any) => ({...prevState, exp_year: newValue.getUTCFullYear() }));
                      setExpData(newValue);
                    }
                  }}
                  renderInput={(params) => <TextField sx={{...styles.input, ...styles.cardInput, marginLeft: "20px"}} {...params} helperText={null} />}
                />
              </LocalizationProvider>
            </Box>
            {/*<Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>*/}
              {/*<TextField*/}
              {/*  select*/}
              {/*  required*/}
              {/*  sx={{...styles.dropDownInput, ...styles.cardInput, ...styles.input}}*/}
              {/*  defaultValue={'credit'}*/}
              {/*  onChange={(e) => updateCardDraft((prevState: any) => ({...prevState, cvc: e.target.value }))}*/}
              {/*>*/}
              {/*  <MenuItem key={'credit'} value={'credit'} sx={{fontSize: 16}}>*/}
              {/*    Credit*/}
              {/*  </MenuItem>*/}
              {/*  <MenuItem key={'debit'} value={'debit'} sx={{fontSize: 16}}>*/}
              {/*    Debit*/}
              {/*  </MenuItem>*/}
              {/*</TextField>*/}
            {/*</Box>*/}
            {/*<Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>*/}
              {/*<TextField*/}
              {/*  required*/}
              {/*  select*/}
              {/*  sx={{...styles.dropDownInput, ...styles.cardInput, ...styles.input}}*/}
              {/*  defaultValue={'visa'}*/}
              {/*>*/}
              {/*  <MenuItem key={'visa'} value={'visa'} sx={{fontSize: 16}}>*/}
              {/*    Visa*/}
              {/*  </MenuItem>*/}
              {/*  <MenuItem key={'mastercard'} value={'mastercard'} sx={{fontSize: 16}}>*/}
              {/*    Mastercard*/}
              {/*  </MenuItem>*/}
              {/*</TextField>*/}
              {/*<TextField required label="ZIP" variant="outlined" type="number" sx={{...styles.input, ...styles.cardInput, marginLeft: "20px"}} value={cardDraft.zip}*/}
              {/*           onChange={e => updateCardDraft((prevState: any) => ({...prevState, zip: e.target.value }))}/>*/}
            {/*</Box>*/}
            {/*<TextField required fullWidth label="Card Holder Name" variant="outlined" sx={{...styles.input, }} value={cardDraft.name_oncard}*/}
            {/*           onChange={e => updateCardDraft((prevState: any) => ({...prevState, name_oncard: e.target.value }))}/>*/}
            <StyledButton text="Add card" type="submit" sx={{marginTop: "20px", minWidth: "200px", }}
            disabled={cardDraft.number.length < 12 || cardDraft.cvc.length < 2}
            />
            </form>
          </Box>
        </Box>
    </Modal>
  )
}