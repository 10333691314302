import api from '../api';

export class FileService {
  static async uploadProfilePicture (file: any) {
    const email = localStorage.getItem('email');
    const URL = `/uploadProfilePicture`;
    const formData = new FormData();
    formData.append('data', file);
    formData.append('email', email ? email : ``);

    const {data} = await api.post(URL, formData);
    return data;
  }
}
