import { InitialDashboardState } from './constants';
import DashboardTypes from './types';

const initialState = {
  ...InitialDashboardState
};

const changeField = (draft: any, fieldName: any, value: any) => ({
  ...draft,
  [fieldName]: value,
});

export default (state = initialState, action: any = "SET_ORDERS") => {
  const newState = { ...state };

  switch (action.type) {
    // case UserTypes.UPDATE_USER_DRAFT:
    //   return {
    //     ...state,
    //     userDraft: changeField(
    //       state.userDraft,
    //       action.payload.fieldName,
    //       action.payload.value
    //     ),
    //   };

    case DashboardTypes.SET_ORDERS:
      return { ...state, orders: action.payload };

    case DashboardTypes.SET_ORDERS_ID_LIST:
      return { ...state, ordersIdList: action.payload };

    case DashboardTypes.SET_SHOW_MORE:
      return {...state, showMore: action.payload };

    case DashboardTypes.ADD_ORDERS_ID_TO_LIST:
      return {...state, ordersIdList: [...state.ordersIdList, ...action.payload]};

    case DashboardTypes.ADD_ORDERS:
      return {...state, orders: [...state.orders, ...action.payload]};

    default:
      return newState;
  }
};