import styles from '../src/styles';
import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../../theme/palette';
import CEO from '../src/CEO.png';

export default () => (
  <Box sx={{...styles.contentBox, justifyContent: "center"}}>
    <Box sx={{minWidth: "400px", ...styles.twoSidedPics,}}>
      <CardMedia sx={{
        height: "500px",
        width: "500px",
        borderRadius: "12px",
        marginRight: "20px",
        ['@media (max-width:1300px)']: {
          height: "460px",
          width: "460px",
        },
        ['@media (max-width:1000px)']: {
          height: "348px",
          width: "348px",
        },
        ['@media (max-width:800px)']: {
          height: "250px",
          width: "250px",
        },
        ['@media (max-width:600px)']: {
          height: "150px",
          width: "150px",
        },
      }} image={require("../src/R2.png")}/>
      <CardMedia sx={{
        height: "666px",
        width: "540px",
        borderRadius: "12px",
        ['@media (max-width:1300px)']: {
          height: "613px",
          width: "460px",
        },
        ['@media (max-width:1000px)']: {
          height: "464px",
          width: "348px",
        },
        ['@media (max-width:800px)']: {
          height: "333px",
          width: "250px",
        },
        ['@media (max-width:600px)']: {
          height: "200px",
          width: "150px",
        },
      }} image={require('../src/R3.png')}/>
    </Box>
    <Box sx={{...styles.innerBox,
      maxWidth: "30%", ['@media (max-width:1600px)']: {
        maxWidth: "70%",
        width: "70%",
        minHeight: "200px"
      }, }}>
      <Typography variant="h2" sx={{...styles.defaultMargin, fontWeight: "bold", ...styles.resizeH2}}>
        Who We Are
      </Typography>
      <Typography variant="h3" sx={{...styles.defaultMargin, ...styles.resizeH3}}>
        We get it. Life is busy. That doesn't
        mean you shouldn't be able to spend
        your time doing the things you love,
        and look flyy doing it.
      </Typography>
      <Typography variant="h3" sx={{...styles.defaultMargin, ...styles.resizeH3}}>
        The world has changed where you
        can get what you want anytime you
        want for a number of things, and
        fashion shouldn't be any different.
      </Typography>
      <Box sx={{...styles.ceoBox}}>
        <Box>
          <img src={CEO} style={{maxHeight: "56px", maxWidth: "56px"}}/>
        </Box>
        <Box sx={{marginLeft: "3%"}}>
          <Typography variant="h3" sx={{fontWeight: 'bold', color: customColors.darkPurple, ...styles.resizeH3}}>
            JORESA BLOUNT
          </Typography>
          <Typography variant="subtitle2" sx={{color: customColors.gray}}>
            FOUNDER-CEO, GOFLYY
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
)