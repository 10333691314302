import { Button } from '@mui/material';
import { customColors } from '../../theme/palette';

const defaultStyles = {
  borderRadius: "20px",
  padding: "10px",
  maxWidth: "100px",
  boxShadow: "0px 0px",
  transition: "",
  "&:hover": {backgroundColor: customColors.secondary}
}

export default (props: any) => {
  const {text, sx, ...rest} = props;
  return (
    <Button variant="contained" fullWidth={false} sx={sx ? {...defaultStyles, ...sx} : {...defaultStyles}} size="medium" {...rest} >
      {text}
    </Button>
  )
};