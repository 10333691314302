import api from '../api';

export class DashboardService {
  static async getOrdersIdsList(email: string, filters?: any) {
    const URL = `/client/getOrdersList`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('limit', '6');
    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        // @ts-ignore
        formData.append(key, value);
      }
    }
    const { data } = await api.post(URL, formData);
    if (data.message === "no_orders_found" || data.status === "404") {
      return [];
    }
    return data;
  }

  static async getOrder(email: string, id: string) {
    const URL = `/client/getMyOrder`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('order_id', id);
    const data = await api.post(URL, formData);
    return data;
  }

  static async setProductReturnReason(email: string, id: string, reason: string) {
    const URL = `/client/setProductReturnReason`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('product_id', id);
    formData.append('return_reason_type', reason);
    formData.append('return_reason_description', reason);
    const { data } = await api.post(URL, formData);
    return data;
  }

  static async resetProductReturnReason(email: string, id: string,) {
    const URL = `/client/resetProductReturnReason`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('product_id', id);
    const { data } = await api.post(URL, formData);
    return data;
  }

  static async submitOrder(email: string, id: string) {
    const URL = `/client/submitOrder`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('order_id', id);
    const { data } = await api.post(URL, formData);
    return data;
  }

  static async clientCalculateSubmittionCharge(email: string, id: string) {
    const URL = `/client/calculateSubmittionCharge`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('order_id', id);
    const { data } = await api.post(URL, formData);
    return data;
  }
}