import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import StyledButton from '../../UI/StyledButton';
import { customColors } from '../../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import { useNavigate } from 'react-router-dom';

const breaks = breakPoints(1450, 1254,724, 40, 300);

export default () => {
  const navigate = useNavigate();
  return(
    <Box sx={{...styles.contentBox, flexWrap: "wrap-reverse",
      ['@media (max-width:1450px)']: {
        flexDirection: "column-reverse",
        alignItems: "center",
      },}}>
      <Box sx={{...styles.innerBox, minWidth: "200px", width: "25%",
        ['@media (max-width:1450px)']: {
          width: "30%",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "center",
          marginTop: "15px",
          minHeight: "200px"
        },
        ['@media (max-width:900px)']: {
          width: "70%",
        },}}>
        <Typography variant="h2" sx={{fontWeight: "bold", ...styles.resizeH2}}>
          You can get
        </Typography>
        <Typography variant="h2" sx={{marginBottom: "9%", fontWeight: "bold", ...styles.resizeH2}}>
          tryouts
        </Typography>
        <Typography variant="h4" color={customColors.darkGray} sx={{marginBottom: "9%",}}>
          The retailer will help you to
          complete your look, try things on
          and decide whether it suits you.
        </Typography>
        <StyledButton text="Sign Up" sx={{maxWidth: "150px", width: "150px", padding: "18px 0px 18px 0px", fontSize: 18, fontWeight: "bold"}} onClick={() => navigate('/registration')}/>
      </Box>
      <CardMedia sx={{
        height: "724px",
        width: "1254px",
        borderRadius: "12px",
        ['@media (max-width:1700px)']: {
          height: "548px",
          width: "950px",
        },
        ...breaks

      }} image={require("../src/R1.jpg")}/>
    </Box>
  )
}