import { Box, Button, CardMedia, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import styles from "./src/styles";
import React, { useState } from 'react';
import StyledButton from '../UI/StyledButton';
import { customColors } from '../../theme/palette';
import GFIcon from '../UI/GFIcon';
import Assets from './components/Assets';
import AssetsPhone from './components/AssetsPhone';
import { testEmail } from './constants';
import { useNavigate } from 'react-router-dom';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import { SignService } from '../../api/services/sign-service';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useActions } from '../../hooks/useHooks';

export default () => {
  useAuthRedirect(false);
  const { setToken, setEmail } = useAuth();
  const [email, setEmailForm] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [pass, setPass] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {showErrorMessage} = useActions();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const res = await SignService.login(email, pass);
      if (res.data.status === '200' && res.data.session_token && res.data.account_type === "client") {
        localStorage.setItem('token', res.data.session_token);
        localStorage.setItem('email', email);
        setToken(res.data.session_token);
        setEmail(email);
      } else {
        switch (res.data.message) {
          case "email_is_not_confirmed": {
            showErrorMessage(`Email is not confirmed`);
            break;
          }
          default: {
            showErrorMessage();
            break;
          }
        }
      }
    } catch (e) {
      showErrorMessage();
    }

  };

  return (
    <Box sx={{...styles.generalBox}}>
      <Box sx={{...styles.contentBox}}>
        <Button sx={{marginTop: "40px"}} onClick={() => navigate('/')}>
          <GFIcon />
        </Button>
        <Box sx={{...styles.loginBox}}>
          <form onSubmit={handleSubmit} style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}>
            <Typography sx={{fontSize: 32, marginBottom: "20px", fontWeight: 'bold'}}>Log in account</Typography>
            <TextField fullWidth required label="Email address" variant="outlined" sx={{...styles.input,}} onChange={e => {
              setEmailForm(e.target.value);
              setValidEmail(testEmail(e.target.value));
            }}/>
            <TextField type={showPassword ? "text" : "password"} fullWidth required label="Password" variant="outlined" sx={{...styles.input,}} onChange={e => {
              setPass(e.target.value);
            }}
           InputProps={{
             endAdornment: (
               <InputAdornment position="end">
                 <IconButton
                   aria-label="toggle password visibility"
                   onClick={handleClickShowPassword}
                   onMouseDown={handleMouseDownPassword}
                 >
                   {showPassword ? <Visibility /> : <VisibilityOff />}
                 </IconButton>
               </InputAdornment>
             )
           }}
            />
            <Box sx={{...styles.buttonBox}}>
              <StyledButton text="Log in" type="submit" sx={{...styles.signInButtons}} disabled={!validEmail || !(pass.length >= 10)}/>
              <Button onClick={() => navigate('/registration')} variant="outlined" sx={{...styles.signInButtons, borderRadius: "20px", /*borderWidth: "3px",*/ color: customColors.black}}>
                Sign up
              </Button>
              <Link sx={{color: customColors.black, fontSize: 14}} onClick={() => navigate("/reset-password")}>Forgot password?</Link>
            </Box>
          </form>
        </Box>
        {/*<Box sx={{...styles.orBox}}>*/}
        {/*  <Typography variant="body1">or</Typography>*/}
        {/*</Box>*/}
        {/*<Assets />*/}
      </Box>
      {/*<AssetsPhone />*/}
    </Box>
  )
}