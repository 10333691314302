import { Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SignService } from '../../api/services/sign-service';
import { useActions } from '../../hooks/useHooks';

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {showErrorMessage, showSuccessMessage} = useActions();

  useEffect(() => {
    try {
      const email = searchParams.get("email");
      const code = searchParams.get("code");
      if (email && code) {
        SignService.verifyEmail(email, code)
          .then(() => {
            showSuccessMessage();
            navigate('/login');
          })
          .catch((e) => {
            showErrorMessage();
            navigate('/login');
          })
      }
    } catch (e) {
      showErrorMessage();
    }
  }, []);
  return (
    <Box>

    </Box>
  )
}