import styles from '../src/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { customColors } from '../../../theme/palette';

export default ({faq: accordions, phone}: any) => (
  <Box sx={{...styles.contentBox, display: { xs: 'flex', xl: 'none' }}}>
      <Box sx={{...styles.faqTabletBox}}>
        <Typography variant="h3" sx={{...styles.defaultMargin, fontWeight: "bold", textAlign: "center", ...styles.resizeH3}}>
          FAQ
        </Typography>
        <Box>
          {accordions.map((qna: any, index: any) => (
            <Accordion key={index} sx={{borderRadius: "15px", backgroundColor: customColors.lightGray, marginBottom: "5px", boxShadow: "0px 0px", '&:before': {
                display: 'none',
              }
            }} square>
              <AccordionSummary
                expandIcon={(
                  <Box sx={{backgroundColor: customColors.primary, borderRadius: "30%", width: "24px", height: "24px"}}>
                    <ExpandMore />
                  </Box>
                )}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{fontSize: 20}}>{qna.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle1">
                  {qna.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
        <Typography variant="subtitle1" sx={{...styles.defaultMargin, marginTop: "4%"}}>
          Where can I go if I have more questions?
        </Typography>
        <Typography variant="subtitle2" sx={{...styles.defaultMargin}}>
          If you'd like to speak to our support, you can reach us via online chat in your dashboard or call us Monday-Friday:
        </Typography>
        <Box sx={{display: "flex", alignItems: "center",}}>
          <Typography variant="subtitle1">
            United States:
          </Typography>
          <Link href={`tel:+1 ${phone}`}
                sx={{color: "white", marginLeft: "10px",
                  display: "flex", alignItems: "center", minHeight: "28px"
                }}
          >+1 {phone}</Link>
        </Box>
      </Box>
  </Box>
);