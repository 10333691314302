import { customColors } from '../../../theme/palette';


export default {
  generalBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: customColors.primary,
    height: "100%",
    minHeight: "969px",
  },
  contentBox: {
    width: "90%",
    height: "90%",
    minHeight: "800px",
    display: "flex",
    borderBottomRightRadius: "64px",
    borderBottomLeftRadius: "64px",
    backgroundColor: customColors.white,
    flexDirection: "column",
    alignItems: "center",
    ['@media (max-width:1439px)']: {
      width: "96%"
    },
  },
  loginBox: {
    width: "40%",
    minHeight: "400px",
    height: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    flexDirection: "row",
    textAlign: "center",
    paddingTop: "2%",
    paddingBottom: "2%",
    backgroundColor: customColors.lightGray,
    borderRadius: "64px",
    marginTop: "40px",
    ['@media (max-width:1439px)']: {
      width: "60%"
    },
    ['@media (max-width:1007px)']: {
      width: "80%"
    },
    ['@media (max-width:640px)']: {
      width: "100%",
      backgroundColor: customColors.white
    },
  },
  input: {
    marginBottom: "10px",
    [`& fieldset`]: {
      fontSize: "16px",
      borderRadius: "16px"
    },
  },
  signUpButtons: {
    height: '50px',
    width: '45%',
    maxWidth: '45%',
    marginRight: '4%',
    ['@media (max-width:640px)']: {
      marginRight: '0px',
      width: "100%",
      maxWidth: "100%"
    },
  },
  signInButtons: {
    height: '50px',
    width: '32%',
    maxWidth: '32%',
    marginRight: '4%',
    ['@media (max-width:640px)']: {
      marginRight: '0px',
      width: "100%",
      maxWidth: "100%",
      marginBottom: "3%"
    },
  },
  assetsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40%",
    minHeight: "100px",
    height: "20%",
    border: `2px solid ${customColors.lightGray}`,
    borderRadius: "32px",
    ['@media (max-width:1439px)']: {
      width: "60%"
    },
    ['@media (max-width:1007px)']: {
      width: "80%"
    },
    ['@media (max-width:640px)']: {
      width: "96%"
    },
  },
  assetButton: {
    fontSize: "16px",
    borderRadius: "16px",
    fontWeight: "bold",
    maxHeight: "60px",
    height: "60px",
    color: customColors.black,
    backgroundColor: customColors.lightGray,
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  assetButtonPhone: {
    borderRadius: "16px",
    maxHeight: "50px",
    height: "50px",
    color: customColors.black,
    border: `2px solid ${customColors.lightGray}`,
  },
  orBox: {
    width: "36px",
    height: "24px",
    backgroundColor: customColors.black,
    color: customColors.white,
    display: { xs: 'none', phone: 'flex' },
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "20px"
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ['@media (max-width:640px)']: {
      flexDirection: "column",
      justifyContent: "space-around",
      minHeight: "140px",
    },
  },
}