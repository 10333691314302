import styles from '../src/styles';
import { customColors } from '../../../theme/palette';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import StyledButton from '../../UI/StyledButton';
import React from 'react';
import R5 from '../src/R5.png';
import { ReactComponent as Apple } from "../src/assets/Apple.svg";
import { ReactComponent as GooglePlay } from "../src/assets/GP.svg";
import { ReactComponent as Windows } from "../src/assets/Windows.svg";
import { useNavigate } from 'react-router-dom';


export default ({appLinks}: any) => {
  const navigate = useNavigate();
  return (
    <Box sx={{...styles.specialContentBox, width: "100%", backgroundColor: customColors.primary, backgroundImage: `url(${R5})`, ...styles.expBox}}>
      <Box sx={{...styles.innerBox, ...styles.sidedContent, ...styles.left, minWidth: "500px", width: "700px", marginLeft: "5%",
        ['@media (max-width:500px)']: {
          marginTop: "50px",
          justifyContent: "flex-start",
          minWidth: "80%",
          width: "80%",
        },}}>
        <CardMedia sx={{
          height: "56px",
          width: "56px",
          marginBottom: "5px",
          ['@media (max-width:800px)']: {
            height: "32px",
            width: "32px",
          },
        }} image={require("../src/Star.png")}/>
        <Typography variant="h1" sx={{fontWeight: "500", ...styles.resizeH1}}>
          <b>Experience</b> the
        </Typography>
        <Typography variant="h1" sx={{...styles.defaultMargin, fontWeight: "500", ...styles.resizeH1}}>
          GoFlyy Lifestyle
        </Typography>
        <Typography variant="h3" sx={{...styles.defaultMargin, width: "300px", ...styles.resizeH3, fontWeight: 500}}>
          No need to wait long - we'll deliver
          and return goods the same day.
        </Typography>
        <StyledButton sx={{...styles.signUp, padding: "15px", minWidth: "150px", ...styles.defaultMargin, backgroundColor: customColors.purple, color: customColors.white, "&:hover": {backgroundColor: customColors.darkPurple}}} text="Sign Up"  onClick={() => navigate('/registration')}/>
        <Box sx={{...styles.defaultMargin, marginTop: "5%"}}>
          { appLinks.ios_app_link ?
            <Button href={appLinks.ios_app_link}>
              <Apple style={{ ...styles.imageIcon }} />
            </Button>
            : null}
          { appLinks.android_app_link ?
            <Button href={appLinks.android_app_link}>
              <GooglePlay style={{ ...styles.imageIcon }} />
            </Button>
            : null}
          { appLinks.win_app_link ?
            <Button href={appLinks.win_app_link}>
              <Windows style={{ ...styles.imageIcon }} />
            </Button>
            : null}
        </Box>
      </Box>
      <Box sx={{width: "100%"}}>

      </Box>
    </Box>
  )
}