import { useActions } from '../../hooks/useHooks';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/lab';
import { Snackbar, Typography } from '@mui/material';

export const AppSnackbar = () => {
  const { clearMessage } = useActions();
  const { open, message, type } = useSelector((state: any) => state.snackbar);

  const handleClose = (_: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    clearMessage();
  };

  if (!type) return null;

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={5000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              sx={{
                height: "200px",
              }}
    >
      <Alert severity={type}
             sx={{
               alignItems: "center",
               "& .MuiAlert-icon": {
                 width: "22px",
                 height: "22px",
               }
             }}
      >
        <Typography sx={{
          fontSize: 18,
        }}>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};
