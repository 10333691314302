import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import styles from "../Dashboard/src/styles";
import { useAuthRedirect } from '../../utils/use-auth';
import { useActions, useUser } from '../../hooks/useHooks';
import { useEffect, useState } from 'react';
import { UserService } from '../../api/services/user-service';
import Loader from '../LandingPage/components/Loader';
import landStyles from '../LandingPage/src/styles';
import { customColors } from '../../theme/palette';
import MuiPhoneNumber from 'material-ui-phone-number';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import StyledButton from '../UI/StyledButton';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../Header/DashboardHeader';
import { InitialUserState } from '../../redux/user/constants';

const InitialProductState = {
  name: '',
  price: '',
  weight: '',
  description: '',
  height: '',
  width: '',
  length: '',
  return_reason: ''
};

export default () => {
  useAuthRedirect();
  const {userDraft} = useUser();
  const [retailerCompany, setRetailerCompany] = useState('');
  const [userInfo, setUserInfo] = useState(userDraft);
  const [firstProduct, setFirstProduct] = useState(InitialProductState);
  const [secondProduct, setSecondProduct] = useState(InitialProductState);
  const [thirdProduct, setThirdProduct] = useState(InitialProductState);
  const [fourthProduct, setFourthProduct] = useState(InitialProductState);
  const [fiveProduct, setFiveProduct] = useState(InitialProductState);
  const [loading, setLoading] = useState(true);
  const [retailers, setRetailers] = useState([]);
  const [retailersEmails, setRetailersEmails]: any = useState({});
  const [returnReasons, setReturnReasons] = useState([]);
  const [productAmount, setProductAmount] = useState(1);
  const navigate = useNavigate();
  const {showErrorMessage, showSuccessMessage, getCurrentUser} = useActions();

  useEffect(() => {
    const email = localStorage.getItem('email');
    try {
      if (email) {
        UserService.getListOfAllRetailers(email)
          .then(({ message: retails }) => {
            setRetailers(retails);
            const res: any = {};
            for (const ret of retails) {
              res[ret.retailer_id] = {
                email: ret.retailer_email,
                address: ret.details[0].company_address
              }
            }
            setRetailersEmails(res);
            UserService.getReturnReasonsList(email)
              .then(({ message: reasons }) => {
                setReturnReasons(reasons);
                if (JSON.stringify(userInfo) === JSON.stringify(InitialUserState)) {
                  getCurrentUser()
                    // @ts-ignore
                    .then((usr: any) => {
                      setUserInfo(usr);
                      setLoading(false);
                    })
                } else {
                  setLoading(false);
                }
              });
          })
      }
    } catch (e) {
      showErrorMessage();
    }
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const email = localStorage.getItem('email');
      if (email) {
        setLoading(true);
        const { email: retailer_email, address } = retailersEmails[retailerCompany];
        const products: any = [];
        products.push(firstProduct);
        if (productAmount > 1) products.push(secondProduct);
        if (productAmount > 2) products.push(thirdProduct);
        if (productAmount > 3) products.push(fourthProduct);
        if (productAmount > 4) products.push(fiveProduct);
        const body: any = {
          retailer_id: retailerCompany,
          retailer_email,
          order_type: "return",
          recipient_email: email,

          first_destination_address1: userInfo.address1,
          first_destination_address2: userInfo.address2,
          first_destination_city: userInfo.city,
          first_destination_state: userInfo.state,
          first_destination_zip: userInfo.zip,
          first_destination_country:userInfo.country,

          second_destination_address1: address[0],
          second_destination_address2: address[1],
          second_destination_city: address[2],
          second_destination_state: address[3],
          second_destination_zip: address[4],
          second_destination_country: address[5],

          recipient_first_name: userInfo.first_name,
          recipient_last_name: userInfo.last_name,
          recipient_country_code: userInfo.country_code,
          recipient_phone: userInfo.phone,
          product1_name: firstProduct.name,
          product1_description: firstProduct.description,
          product1_price: firstProduct.price,
          product1_weight: firstProduct.weight,
          product1_dimentions_height: firstProduct.height,
          product1_dimentions_width: firstProduct.width,
          product1_dimentions_length: firstProduct.length,

        };
        if (productAmount > 1) {
          body.product2_name = secondProduct.name;
          body.product2_description = secondProduct.description;
          body.product2_price = secondProduct.price;
          body.product2_weight = secondProduct.weight;
          body.product2_dimentions_height = secondProduct.height;
          body.product2_dimentions_width = secondProduct.width;
          body.product2_dimentions_length = secondProduct.length;
        }
        if (productAmount > 2) {
          body.product3_name = thirdProduct.name;
          body.product3_description = thirdProduct.description;
          body.product3_price = thirdProduct.price;
          body.product3_weight = thirdProduct.weight;
          body.product3_dimentions_height = thirdProduct.height;
          body.product3_dimentions_width = thirdProduct.width;
          body.product3_dimentions_length = thirdProduct.length;
        }
        if (productAmount > 3) {
          body.product4_name = fourthProduct.name;
          body.product4_description = fourthProduct.description;
          body.product4_price = fourthProduct.price;
          body.product4_weight = fourthProduct.weight;
          body.product4_dimentions_height = fourthProduct.height;
          body.product4_dimentions_width = fourthProduct.width;
          body.product4_dimentions_length = fourthProduct.length;
        }
        if (productAmount > 4) {
          body.product5_name = fiveProduct.name;
          body.product5_description = fiveProduct.description;
          body.product5_price = fiveProduct.price;
          body.product5_weight = fiveProduct.weight;
          body.product5_dimentions_height = fiveProduct.height;
          body.product5_dimentions_width = fiveProduct.width;
          body.product5_dimentions_length = fiveProduct.length;
        }

        const resp = await UserService.createReturn(email, body);
        if (resp.status === '200') {
          showSuccessMessage();
          navigate('/dashboard');
        } else {
          showErrorMessage(resp.message);
        }
      }
    } catch (e) {
      showErrorMessage();
    }
  }
  return (
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
      <DashboardHeader />
    <Box sx={{...styles.box}}>
      {!loading ?
        <Box sx={{ ...styles.contentBox, width: '60%', }}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h2" sx={{...styles.defaultMargin, }}>
            New Return
          </Typography>
          <TextField
            select
            required
            sx={{...styles.dropDownInput, ...styles.cardInput, ...styles.input}}
            label="Retailer company"
            onChange={(e) => setRetailerCompany(e.target.value)}
          >
            {
              retailers.map((ret: any) => {
                const { company_name } = ret.details[0];
                return (
                  <MenuItem key={ret.retailer_id} value={ret.retailer_id} sx={{fontSize: 16}}>
                    {company_name}
                  </MenuItem>
                )
              })
            }
          </TextField>

          <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin, marginTop: "50px"}}>
            <Box sx={{display: "flex", flexDirection: "row"}}>
              <Typography sx={{display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold", backgroundColor: customColors.primary, width: "24px", height: "24px", borderRadius: "8px", marginRight: "10px"}}>1</Typography>
              <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Product</Typography>
            </Box>
            <Box sx={{ ...styles.defaultMargin}}>
              <TextField required label="Name" fullWidth variant="outlined" type="required" sx={{...styles.input}} value={firstProduct.name}
                         onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, name: e.target.value }) )}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="Price" variant="outlined" type="number" sx={{...styles.input}} value={firstProduct.price}
                         onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, price: e.target.value }) )}/>
              <TextField required label="Weight" variant="outlined" type="number" sx={{...styles.input, marginLeft: "20px"}} value={firstProduct.weight}
                         onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, weight: e.target.value }) )}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin}}>
              <TextField required label="Description" fullWidth variant="outlined" type="required" sx={{...styles.input}} value={firstProduct.description}
                         onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, description: e.target.value }) )}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="Height" variant="outlined" type="number" sx={{...styles.input, minWidth: "165px"}} value={firstProduct.height}
                         onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, height: e.target.value }) )}/>
              <TextField required label="Width" variant="outlined" type="number" sx={{...styles.input, minWidth: "165px", marginLeft: "20px"}} value={firstProduct.width}
                         onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, width: e.target.value }) )}/>
              <TextField required label="Length" variant="outlined" type="number" sx={{...styles.input, minWidth: "165px", marginLeft: "20px"}} value={firstProduct.length}
                         onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, length: e.target.value }) )}/>
            </Box>
            <TextField
              select
              required
              fullWidth
              sx={{...styles.dropDownInput, ...styles.cardInput, ...styles.input, width: "100%"}}
              label="Pick up return reason"
              onChange={e => setFirstProduct((prevState: any) => ({ ...prevState, return_reason: e.target.value }) )}
            >
              {
                returnReasons.map((ret: any) => {
                  const { reason } = ret;
                  return (
                    <MenuItem key={reason} value={ret.id} sx={{fontSize: 16}}>
                      {reason}
                    </MenuItem>
                  )
                })
              }
            </TextField>
          </Box>

          { productAmount >= 2 ?
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '540px', ...landStyles.defaultMargin,
              marginTop: '50px',
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  backgroundColor: customColors.primary,
                  width: '24px',
                  height: '24px',
                  borderRadius: '8px',
                  marginRight: '10px',
                }}>2</Typography>
                <Typography variant='subtitle1'
                            sx={{ color: customColors.darkGray, ...styles.defaultMargin }}>Product</Typography>
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Name' fullWidth variant='outlined' type='required' sx={{ ...styles.input }}
                           value={secondProduct.name}
                           onChange={e => setSecondProduct((prevState: any) => ({
                             ...prevState,
                             name: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Price' variant='outlined' type='number' sx={{ ...styles.input }}
                           value={secondProduct.price}
                           onChange={e => setSecondProduct((prevState: any) => ({
                             ...prevState,
                             price: e.target.value,
                           }))} />
                <TextField required label='Weight' variant='outlined' type='number'
                           sx={{ ...styles.input, marginLeft: '20px' }} value={secondProduct.weight}
                           onChange={e => setSecondProduct((prevState: any) => ({
                             ...prevState,
                             weight: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Description' fullWidth variant='outlined' type='required'
                           sx={{ ...styles.input }} value={secondProduct.description}
                           onChange={e => setSecondProduct((prevState: any) => ({
                             ...prevState,
                             description: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Height' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px' }} value={secondProduct.height}
                           onChange={e => setSecondProduct((prevState: any) => ({
                             ...prevState,
                             height: e.target.value,
                           }))} />
                <TextField required label='Width' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={secondProduct.width}
                           onChange={e => setSecondProduct((prevState: any) => ({
                             ...prevState,
                             width: e.target.value,
                           }))} />
                <TextField required label='Length' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={secondProduct.length}
                           onChange={e => setSecondProduct((prevState: any) => ({
                             ...prevState,
                             length: e.target.value,
                           }))} />
              </Box>
              <TextField
                select
                required
                fullWidth
                sx={{ ...styles.dropDownInput, ...styles.cardInput, ...styles.input, width: '100%' }}
                label='Pick up return reason'
              >
                {
                  returnReasons.map((ret: any) => {
                    const { reason } = ret;
                    return (
                      <MenuItem key={reason} value={ret.id} sx={{ fontSize: 16 }}>
                        {reason}
                      </MenuItem>
                    );
                  })
                }
              </TextField>
            </Box> : null
          }

          { productAmount >= 3 ?
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '540px', ...landStyles.defaultMargin,
              marginTop: '50px',
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  backgroundColor: customColors.primary,
                  width: '24px',
                  height: '24px',
                  borderRadius: '8px',
                  marginRight: '10px',
                }}>3</Typography>
                <Typography variant='subtitle1'
                            sx={{ color: customColors.darkGray, ...styles.defaultMargin }}>Product</Typography>
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Name' fullWidth variant='outlined' type='required' sx={{ ...styles.input }}
                           value={thirdProduct.name}
                           onChange={e => setThirdProduct((prevState: any) => ({
                             ...prevState,
                             name: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Price' variant='outlined' type='number' sx={{ ...styles.input }}
                           value={thirdProduct.price}
                           onChange={e => setThirdProduct((prevState: any) => ({
                             ...prevState,
                             price: e.target.value,
                           }))} />
                <TextField required label='Weight' variant='outlined' type='number'
                           sx={{ ...styles.input, marginLeft: '20px' }} value={thirdProduct.weight}
                           onChange={e => setThirdProduct((prevState: any) => ({
                             ...prevState,
                             weight: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Description' fullWidth variant='outlined' type='required'
                           sx={{ ...styles.input }} value={thirdProduct.description}
                           onChange={e => setThirdProduct((prevState: any) => ({
                             ...prevState,
                             description: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Height' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px' }} value={thirdProduct.height}
                           onChange={e => setThirdProduct((prevState: any) => ({
                             ...prevState,
                             height: e.target.value,
                           }))} />
                <TextField required label='Width' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={thirdProduct.width}
                           onChange={e => setThirdProduct((prevState: any) => ({
                             ...prevState,
                             width: e.target.value,
                           }))} />
                <TextField required label='Length' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={thirdProduct.length}
                           onChange={e => setThirdProduct((prevState: any) => ({
                             ...prevState,
                             length: e.target.value,
                           }))} />
              </Box>
              <TextField
                select
                required
                fullWidth
                sx={{ ...styles.dropDownInput, ...styles.cardInput, ...styles.input, width: '100%' }}
                label='Pick up return reason'
              >
                {
                  returnReasons.map((ret: any) => {
                    const { reason } = ret;
                    return (
                      <MenuItem key={reason} value={ret.id} sx={{ fontSize: 16 }}>
                        {reason}
                      </MenuItem>
                    );
                  })
                }
              </TextField>
            </Box> : null
          }
          { productAmount >= 4 ?
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '540px', ...landStyles.defaultMargin,
              marginTop: '50px',
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  backgroundColor: customColors.primary,
                  width: '24px',
                  height: '24px',
                  borderRadius: '8px',
                  marginRight: '10px',
                }}>4</Typography>
                <Typography variant='subtitle1'
                            sx={{ color: customColors.darkGray, ...styles.defaultMargin }}>Product</Typography>
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Name' fullWidth variant='outlined' type='required' sx={{ ...styles.input }}
                           value={fourthProduct.name}
                           onChange={e => setFourthProduct((prevState: any) => ({
                             ...prevState,
                             name: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Price' variant='outlined' type='number' sx={{ ...styles.input }}
                           value={fourthProduct.price}
                           onChange={e => setFourthProduct((prevState: any) => ({
                             ...prevState,
                             price: e.target.value,
                           }))} />
                <TextField required label='Weight' variant='outlined' type='number'
                           sx={{ ...styles.input, marginLeft: '20px' }} value={fourthProduct.weight}
                           onChange={e => setFourthProduct((prevState: any) => ({
                             ...prevState,
                             weight: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Description' fullWidth variant='outlined' type='required'
                           sx={{ ...styles.input }} value={fourthProduct.description}
                           onChange={e => setFourthProduct((prevState: any) => ({
                             ...prevState,
                             description: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Height' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px' }} value={fourthProduct.height}
                           onChange={e => setFourthProduct((prevState: any) => ({
                             ...prevState,
                             height: e.target.value,
                           }))} />
                <TextField required label='Width' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={fourthProduct.width}
                           onChange={e => setFourthProduct((prevState: any) => ({
                             ...prevState,
                             width: e.target.value,
                           }))} />
                <TextField required label='Length' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={fourthProduct.length}
                           onChange={e => setFourthProduct((prevState: any) => ({
                             ...prevState,
                             length: e.target.value,
                           }))} />
              </Box>
              <TextField
                select
                required
                fullWidth
                sx={{ ...styles.dropDownInput, ...styles.cardInput, ...styles.input, width: '100%' }}
                label='Pick up return reason'
              >
                {
                  returnReasons.map((ret: any) => {
                    const { reason } = ret;
                    return (
                      <MenuItem key={reason} value={ret.id} sx={{ fontSize: 16 }}>
                        {reason}
                      </MenuItem>
                    );
                  })
                }
              </TextField>
            </Box> : null
          }
          { productAmount >= 5 ?
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '540px', ...landStyles.defaultMargin,
              marginTop: '50px',
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  backgroundColor: customColors.primary,
                  width: '24px',
                  height: '24px',
                  borderRadius: '8px',
                  marginRight: '10px',
                }}>5</Typography>
                <Typography variant='subtitle1'
                            sx={{ color: customColors.darkGray, ...styles.defaultMargin }}>Product</Typography>
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Name' fullWidth variant='outlined' type='required' sx={{ ...styles.input }}
                           value={fiveProduct.name}
                           onChange={e => setFiveProduct((prevState: any) => ({
                             ...prevState,
                             name: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Price' variant='outlined' type='number' sx={{ ...styles.input }}
                           value={fiveProduct.price}
                           onChange={e => setFiveProduct((prevState: any) => ({
                             ...prevState,
                             price: e.target.value,
                           }))} />
                <TextField required label='Weight' variant='outlined' type='number'
                           sx={{ ...styles.input, marginLeft: '20px' }} value={fiveProduct.weight}
                           onChange={e => setFiveProduct((prevState: any) => ({
                             ...prevState,
                             weight: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin }}>
                <TextField required label='Description' fullWidth variant='outlined' type='required'
                           sx={{ ...styles.input }} value={fiveProduct.description}
                           onChange={e => setFiveProduct((prevState: any) => ({
                             ...prevState,
                             description: e.target.value,
                           }))} />
              </Box>
              <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox }}>
                <TextField required label='Height' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px' }} value={fiveProduct.height}
                           onChange={e => setFiveProduct((prevState: any) => ({
                             ...prevState,
                             height: e.target.value,
                           }))} />
                <TextField required label='Width' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={fiveProduct.width}
                           onChange={e => setFiveProduct((prevState: any) => ({
                             ...prevState,
                             width: e.target.value,
                           }))} />
                <TextField required label='Length' variant='outlined' type='number'
                           sx={{ ...styles.input, minWidth: '165px', marginLeft: '20px' }} value={fiveProduct.length}
                           onChange={e => setFiveProduct((prevState: any) => ({
                             ...prevState,
                             length: e.target.value,
                           }))} />
              </Box>
              <TextField
                select
                required
                fullWidth
                sx={{ ...styles.dropDownInput, ...styles.cardInput, ...styles.input, width: '100%' }}
                label='Pick up return reason'
              >
                {
                  returnReasons.map((ret: any) => {
                    const { reason } = ret;
                    return (
                      <MenuItem key={reason} value={ret.id} sx={{ fontSize: 16 }}>
                        {reason}
                      </MenuItem>
                    );
                  })
                }
              </TextField>
            </Box> : null
          }

          {
            productAmount < 5 ?
              <Button variant="outlined" sx={{
                color: customColors.black,
                width: "260px",
                height: "56px",
                borderRadius: "16px",
              }}
              onClick={() => setProductAmount(productAmount + 1)}
              >
                Add product
              </Button>
              : null
          }
          <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin, marginTop: "50px"}}>
            <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Contact information</Typography>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="First Name" variant="outlined" type="required" sx={{...styles.input}} value={userInfo.first_name}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, first_name: e.target.value }) )}/>
              <TextField required label="Last Name" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={userInfo.last_name}
              onChange={e => setUserInfo((prevState: any) => ({ ...prevState, last_name: e.target.value }) )}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <MuiPhoneNumber defaultCountry={'us'}
                              variant="outlined"
                              sx={{...styles.input, justifyContent: "center"}}
                              excludeCountries={['ru', ]}
                              onlyCountries={['us']}
                              disableAreaCodes={true}
                              value={userInfo.country_code ? (userInfo.country_code + userInfo.phone) : userInfo.phone}
                              onChange={e => {
                                if (typeof e === 'string') {
                                  const numbers = e.split(' (');
                                  setUserInfo((prevState: any) => ({ ...prevState, country_code: '+ 1' }) );
                                  const regionCode = numbers[1] ? numbers[1].split(') ') : ['', ''];
                                  const phoneNumbers = regionCode[1] ? regionCode[1].split('-') : ['', ''];
                                  setUserInfo((prevState: any) => ({ ...prevState, phone: regionCode[0] + phoneNumbers[0] + phoneNumbers[1] }) );
                                }
                              }}/>
              <TextField required label="E-mail address" variant="outlined" inputProps={{ readOnly: true }} sx={{...styles.input, marginLeft: "20px"}} value={localStorage.getItem('email')}
              //onChange={e => setUserInfo((prevState: any) => ({ ...prevState, email: e.target.value }) )}
              />
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  openTo="year"
                  views={['year', 'month', 'day']}
                  label="Date of birth"
                  value={userInfo.date}
                  onChange={(newValue: any) => {
                    setUserInfo((prevState: any) => ({ ...prevState, date: newValue }) )
                  }}
                  renderInput={(params) => <TextField sx={{...styles.input}} {...params} helperText={null} />}
                />
              </LocalizationProvider>
              <TextField select label="Gender" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={userInfo.sex}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, sex: e.target.value }) )}>
                <MenuItem key={'male'} value={'male'} sx={{fontSize: 16}}>
                  Male
                </MenuItem>
                <MenuItem key={'female'} value={'female'} sx={{fontSize: 16}}>
                  Female
                </MenuItem>
                <MenuItem key={undefined} value={undefined} sx={{fontSize: 16}}>
                  Prefer not to say
                </MenuItem>
              </TextField>
            </Box>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin}}>
            <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Pick-Up address</Typography>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="Country" variant="outlined" type="required" sx={{...styles.input}} value={userInfo.country}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, country: e.target.value }) )}/>
              <TextField required label="State" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={userInfo.state}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, state: e.target.value }) )}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="City" variant="outlined" type="required" sx={{...styles.input}} value={userInfo.city}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, city: e.target.value }) )}/>
              <TextField required label="ZIP" type="number" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={userInfo.zip}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, zip: e.target.value }) )} inputProps={
                { readOnly: !!userInfo.zip, }
              }/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="Address" variant="outlined" type="required" sx={{...styles.input}} value={userInfo.address1}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, address1: e.target.value }) )}/>
              <TextField label="Second Address" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={userInfo.address2}
                         onChange={e => setUserInfo((prevState: any) => ({ ...prevState, address2: e.target.value }) )}/>
            </Box>
            <TextField fullWidth label="Details" variant="outlined" sx={{...styles.input}} value={userInfo.address_details}
                       onChange={e => setUserInfo((prevState: any) => ({ ...prevState, address_details: e.target.value }) )}/>
          </Box>
          <StyledButton text="Return" type="submit" sx={{
            minWidth: "200px"
          }} />
        </form>
      </Box>
      : <Loader />
      }
    </Box>
    </Box>
  )
}