import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem, Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useCallback } from 'react';
import GFIcon from '../UI/GFIcon';
import palette, { customColors } from '../../theme/palette';
import StyledButton from '../UI/StyledButton';
import { useNavigate } from 'react-router-dom';
import dashStyles from "../Dashboard/src/styles";
import {ReactComponent as ProfileIcon} from './src/prof.svg';
import {ReactComponent as NotificationIcon} from './src/notif.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import { SignService } from '../../api/services/sign-service';
import { useActions } from '../../hooks/useHooks';

const linksByName: {[index: string]:any} = {
  About: '/about'
}

const styles = {
  appBar: {
    backgroundColor: customColors.white,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px"
  },
  signUp: {
    color: customColors.black,
    backgroundColor: customColors.primary,
    marginLeft: "15px",
    width: "200px",
    borderRadius: "15px"
  },
  toolBar: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  cont: {
    marginLeft: "1%",
    marginRight: "1%",
    maxWidth: "100%",
    ['@media (max-width:1536px)']: {
      maxWidth: "90%",
      marginLeft: "0px",
      marginRight: "0px",
    },
    ['@media (max-width:600px)']: {
      padding: "0px",
    }
  }
}

export default () => {
  const history = useNavigate();
  const {clearUserDraft} = useActions();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = useCallback(({target}: any) => {
    const element = document.getElementById(target.innerText);
    if (element) {
      element.scrollIntoView();
    } else {
      history(linksByName[target.innerText]);
    }
    handleCloseNavMenu();
  }, [history]);

  return (
    <AppBar sx={{ ...styles.appBar }} position="static">
      <Container  maxWidth="xl" sx={{padding: "0px", ...styles.cont}}>
        <Toolbar disableGutters sx={{...styles.toolBar, display: { xs: 'none', phone: 'flex' }}}>
          <Button sx={{ marginRight: "25px",
            ['@media (max-width:500px)']: {
              marginRight: "1%",
              maxWidth: "100px"
            },}} onClick={() => history('/')}>
            <GFIcon />
          </Button>

          <Box sx={{ flexGrow: 0, minWidth: "150px", display: "flex", justifyContent: "center" }}>
            <Button sx={{...dashStyles.button, ...dashStyles.blackButton, marginRight: "10px"}}
              onClick={() => history('/dashboard')}
            >
              My orders
            </Button>
            <Button sx={{...dashStyles.button, ...dashStyles.whiteButton, marginRight: "10px"}}
              onClick={() => history('/support')}
            >
              Support
            </Button>
            <Button sx={{...dashStyles.button, ...dashStyles.whiteButton, marginRight: "10px"}}
              // onClick={() => history('/notifications')}
            >
              <NotificationIcon />
            </Button>
            <Button sx={{...dashStyles.button, ...dashStyles.whiteButton, marginRight: "10px"}}
              onClick={() => history('/profile')}
            >
              <ProfileIcon />
            </Button>
            <Button sx={{...dashStyles.button, ...dashStyles.whiteButton,}}
              onClick={async () => {
                const email = localStorage.getItem('email');
                // @ts-ignore
                await SignService.logout(email);
                clearUserDraft();
                localStorage.clear();
                history('/');
              }}
            >
              <LogoutIcon />
            </Button>
          </Box>
        </Toolbar>
        <Toolbar disableGutters sx={{...styles.toolBar, display: { xs: 'flex', phone: 'none' }}}>
          <Button sx={{
            ['@media (max-width:500px)']: {
              marginLeft: "1%",
              maxWidth: "100px"
            },}} onClick={() => history('/')}>
            <GFIcon />
          </Button>
          <Button sx={{...dashStyles.button, ...dashStyles.blueButton,}}
                  onClick={() => history('/dashboard')}
          >
            My orders
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  )
}