import styles from '../src/styles';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../../theme/palette';
import {ReactComponent as Cart} from '../src/assets/Cart.svg';
import {ReactComponent as Arrow} from '../src/assets/Arrow.svg';
import {ReactComponent as Route} from '../src/assets/Route.svg';
import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();
  return (
    <Box sx={{...styles.contentBox, ['@media (max-width:1300px)']: {
        justifyContent: "center",
      }}} id="Benefits" className="Benefits">
      <Box sx={{...styles.innerBox, ...styles.sidedContent, marginRight: "5%",
        ['@media (max-width:1300px)']: {
          width: "80%",
          maxWidth: "90%",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "0",
          minHeight: "300px",
          textAlign: "center"
        },}}>
        <Typography variant="h2" sx={{...styles.defaultMargin, fontWeight: "bold", ...styles.resizeH2}}>What we do</Typography>
        <Typography color={customColors.darkGray} variant="h4">
          No driving. No lines. No worries.
        </Typography>
        <Typography color={customColors.darkGray} variant="h4">
          We make your shopping
        </Typography>
        <Typography color={customColors.darkGray} variant="h4" sx={{...styles.defaultMargin}}>
          experience comfortable.
        </Typography>
        <Button variant="outlined" sx={{...styles.createAnAcc}} color="info" onClick={() => navigate('/registration')}>
          Create an account
        </Button>
      </Box>
      <Box sx={{...styles.whatWeDoBox, display: { xs: 'none', tablet: 'flex' }}}>
        <Box sx={{...styles.smallerDoBox, marginRight: "2%"}}>
          <Box sx={{...styles.defaultMargin, ...styles.iconBox}}>
            <Cart style={{width: "20px", height: "50px"}}/>
          </Box>
          <Typography variant="subtitle1" sx={{...styles.defaultMargin, fontWeight: "bold"}}>
            Try Before You Buy
          </Typography>
          <Typography variant="subtitle2">
            You can order several items
            of clothing, try them on within 20 minutes, and return what doesn't fit you.
          </Typography>
        </Box>
        <Box sx={{...styles.doBox, marginRight: "2%"}}>
          <Box sx={{...styles.defaultMargin, ...styles.iconBox, backgroundColor: customColors.darkPurple, color: customColors.white}}>
            <Arrow style={{width: "20px", height: "50px"}}/>
          </Box>
          <Typography variant="subtitle1" sx={{...styles.defaultMargin, fontWeight: "bold"}}>
            Returns
          </Typography>
          <Typography variant="subtitle2">
            You can make a return not only to a partner store, but also to any other store in your area.
          </Typography>
          {/*<ul style={{paddingLeft: "10px"}}>*/}
          {/*  <li style={{marginBottom: "10px", listStyleType: "circle"}}>*/}
          {/*    <Typography variant="subtitle2" sx={{marginRight: "10px", maxWidth: "150px", display:"inline-block" }}>*/}
          {/*      Paid by retailer*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="subtitle2" sx={{fontWeight: "bold", textAlign: "center", display:"inline-block", width: "64px", backgroundColor: customColors.green, borderRadius: "8px"}}>*/}
          {/*      FREE*/}
          {/*    </Typography>*/}
          {/*  </li>*/}
          {/*  <li style={{listStyleType: "circle"}}>*/}
          {/*    <Typography variant="subtitle2" sx={{marginRight: "10px", display:"inline-block", }}>*/}
          {/*      Paid by shopper*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="subtitle2" sx={{fontWeight: "bold", textAlign: "center", display:"inline-block", width: "40px", backgroundColor: customColors.primary, borderRadius: "8px"}}>*/}
          {/*      $18*/}
          {/*    </Typography>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </Box>
        <Box sx={{...styles.smallerDoBox}}>
          <Box sx={{...styles.defaultMargin, ...styles.iconBox}}>
            <Route style={{width: "20px", height: "50px"}}/>
          </Box>
          <Typography variant="subtitle1" sx={{...styles.defaultMargin, fontWeight: "bold"}}>
            Delivery
          </Typography>
          <Typography variant="subtitle2">
            GoFlyy’s 99.9% on-time delivery rate and average customer satisfaction rating of 4.9/5 are both best-in-class.
          </Typography>
        </Box>
      </Box>
      <Box sx={{...styles.whatWeDoBoxTablet, display: { xs: 'flex', tablet: 'none' }}}>
        <Box sx={{...styles.smallerDoBox, marginBottom: "2%", width: "90%"}}>
          <Box sx={{...styles.defaultMargin, ...styles.iconBox}}>
            <Cart style={{width: "20px", height: "50px"}}/>
          </Box>
          <Typography variant="subtitle1" sx={{...styles.defaultMargin, fontWeight: "bold"}}>
            Try Before You Buy
          </Typography>
          <Typography variant="subtitle2">
            You can order several items
            of clothing, try them on within 20 minutes, and return what doesn't fit you.
          </Typography>
        </Box>
        <Box sx={{...styles.doBox, marginBottom: "2%", width: "87%"}}>
          <Box sx={{...styles.defaultMargin, ...styles.iconBox, backgroundColor: customColors.darkPurple, color: customColors.white}}>
            <Arrow style={{width: "20px", height: "50px"}}/>
          </Box>
          <Typography variant="subtitle1" sx={{...styles.defaultMargin, fontWeight: "bold"}}>
            Returns
          </Typography>
          <Typography variant="subtitle2">
            You can make a return not only to a partner store, but also to any other store in your area.
          </Typography>
          <ul style={{paddingLeft: "10px"}}>
            <li style={{marginBottom: "10px", listStyleType: "circle"}}>
              <Typography variant="subtitle2" sx={{marginRight: "10px", maxWidth: "150px", display:"inline-block" }}>
                Paid by retailer
              </Typography>
              <Typography variant="subtitle2" sx={{fontWeight: "bold", textAlign: "center", display:"inline-block", width: "64px", backgroundColor: customColors.green, borderRadius: "8px"}}>
                FREE
              </Typography>
            </li>
            <li style={{listStyleType: "circle"}}>
              <Typography variant="subtitle2" sx={{marginRight: "10px", display:"inline-block", }}>
                Paid by shopper
              </Typography>
              <Typography variant="subtitle2" sx={{fontWeight: "bold", textAlign: "center", display:"inline-block", width: "40px", backgroundColor: customColors.primary, borderRadius: "8px"}}>
                $18
              </Typography>
            </li>
          </ul>
        </Box>
        <Box sx={{...styles.smallerDoBox, width: "90%"}}>
          <Box sx={{...styles.defaultMargin, ...styles.iconBox}}>
            <Route style={{width: "20px", height: "50px"}}/>
          </Box>
          <Typography variant="subtitle1" sx={{...styles.defaultMargin, fontWeight: "bold"}}>
            Delivery
          </Typography>
          <Typography variant="subtitle2">
            GoFlyy’s 99.9% on-time delivery rate and average customer satisfaction rating of 4.9/5 are both best-in-class.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}