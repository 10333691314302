export default {
  fontFamily: 'Plus Jakarta Sans',
  h1: {
    fontSize: 72,
    fontWeight: 'bold',
  },

  h2: {
    fontSize: 48,
    fontWeight: 'bold',
  },

  h3: {
    fontSize: 24,
    fontWeight: 'bold',
  },

  h4: {
    fontSize: 24,
    fontWeight: 500,
  },

  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
  },

  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
  },

  body1: {
    fontSize: 12,
    fontWeight: 500,
  },

  body2: {
    fontSize: 10,
    fontWeight: 400,
  },

  button: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: undefined
  },
};
