import styles from '../src/styles';
import { Box, CardMedia, Typography } from '@mui/material';
import StyledButton from '../../UI/StyledButton';
import React from 'react';
import { customColors } from '../../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import { useNavigate } from 'react-router-dom';


const breaks = breakPoints(1400, 1300, 931);

export default () => {
  const navigate = useNavigate();
  return (
    <Box sx={{...styles.contentBox, ['@media (max-width:1400px)']: {
        justifyContent: "center",
      }}}>
      <CardMedia sx={{
        height: "695px",
        width: "970px",
        borderRadius: "12px",
        marginRight: "20px",
        ['@media (max-width:1620px)']: {
          height: "573px",
          width: "800px",
        },
        ...breaks
      }} image={require('../src/R4.png')}/>
      <Box sx={{...styles.innerBox, ...styles.sidedContent, alignItems: "flex-start", paddingLeft: "20px",
        ['@media (max-width:1400px)']: {
          alignItems: "center",
          minHeight: "200px",
          maxWidth: "100%",
          width: "100%",
          minWidth: "100%",
          paddingLeft: "0px"
        },}}>
        <Box sx={{...styles.joinUsBox}}>
          <Typography variant="h3" sx={{...styles.defaultMargin, ...styles.resizeH3}}>
            Join Us Now
          </Typography>
          <Typography variant="subtitle1" sx={{...styles.defaultMargin}}>
            Join a luxury shopping experience with us
          </Typography>
          <StyledButton text="Get Started" size="big" sx={{maxWidth: "200px", "&:hover": {backgroundColor: customColors.secondary}}}  onClick={() => navigate('/registration')}/>
        </Box>
      </Box>

    </Box>
  )
}