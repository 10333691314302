import { Box, Button, CardMedia, MenuItem, Tab, TextField, Typography } from '@mui/material';
import styles from "./src/styles";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { customColors } from '../../theme/palette';
import { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { ORDER_STATUSES, SORT_BY_TIME, statusColor, TEST_ORDERS } from './constants';
import dashStyles from './src/styles';
import OrderModal from './components/OrderModal';
import { useAuthRedirect } from '../../utils/use-auth';
import { useActions, useOrders, useShowMore } from '../../hooks/useHooks';
import Loader from '../LandingPage/components/Loader';
import { parseUTC } from '../../utils/utc-to-date';
import { UserService } from '../../api/services/user-service';
import DashboardHeader from '../Header/DashboardHeader';

export default () => {
  useAuthRedirect();
  const [tabValue, setTabValue] = useState('1');
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [currentOrder, setCurrentOrder] = useState(TEST_ORDERS[0]);
  const [ageDays, setAgeDays] = useState("30");
  const [returnReasons, setReturnReasons] = useState([]);
  const { getOrdersList, getOrdersListWithPage, showErrorMessage, showSuccessMessage, } = useActions();
  const navigate = useNavigate();
  const orders = useOrders();
  const showMore = useShowMore();

  useEffect(() => {
    try {
      const email = localStorage.getItem('email');
      if (email) {
        UserService.getReturnReasonsList(email)
          .then(({ message: reasons }) => {
            setReturnReasons(reasons);
          });
      }
    } catch (e) {
      showErrorMessage();
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      switch (tabValue) {
        case "1": {
          getOrdersList({
            age_days: ageDays,
          })
            // @ts-ignore
            .then((res: any) => {
              if (res.length) {
                setCurrentOrder(res[0]);
              }
              setLoading(false);
            });
          break;
        }
        case "2": {
          getOrdersList({
            age_days: ageDays,
            type_order: "return",
          })
            // @ts-ignore
            .then((res: any) => {
              if (res.length) {
                setCurrentOrder(res[0]);
              }
              setLoading(false);
            });
          break;
        }
      }
    } catch (e) {
      showErrorMessage();
    }
  }, [tabValue, ageDays]);

  const handleShowMore = useCallback(() => {
    try {
      getOrdersListWithPage(page);
      setPage((prevState) => (prevState + 1));
    } catch (e) {
      showErrorMessage();
    }
  }, [page]);

  return (
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
    <DashboardHeader />
    <Box sx={{...styles.box}}>
      <OrderModal modalTrigger={isModalOpen}
                  setModalTrigger={setModalOpen}
                  order={currentOrder}
                  returnReasons={returnReasons}
      />
      <Box sx={{...styles.contentBox, width: "80%"}}>
        <Box sx={{display: "flex", flexDirection: "column", width: "100%", }}>
          <TabContext value={tabValue}>
            <Box sx={{display: "flex", width: "100%",  justifyContent: "space-between",
              "& .MuiTabs-indicator": {
                display: "none"
              }}}>
              <TabList onChange={(e, value) => setTabValue(value)} sx={{borderRadius: "16px", backgroundColor: customColors.lightGray,

              }}>
                <Tab label="My orders" value="1" sx={{...styles.tab}}/>
                <Tab label="My returns" value="2" sx={{...styles.tab}}/>
              </TabList>
              <Box sx={{display: "flex", }}>
                {/*<Button sx={{...styles.button, ...styles.whiteButton, marginRight: "10px"}}>*/}
                {/*  <SearchIcon />*/}
                {/*</Button>*/}
                {/*<TextField*/}
                {/*  select*/}
                {/*  sx={{...styles.dropDownInput, marginRight: "10px"}}*/}
                {/*  defaultValue={ORDER_STATUSES[0]}*/}
                {/*>*/}
                {/*  {ORDER_STATUSES.map((status) => (*/}
                {/*    <MenuItem key={status} value={status} sx={{fontSize: 16}}>*/}
                {/*      {status}*/}
                {/*    </MenuItem>*/}
                {/*  ))}*/}
                {/*</TextField>*/}
                <TextField
                  select
                  sx={{...styles.dropDownInput, marginRight: "10px"}}
                  value={ageDays}
                  onChange={(e) => setAgeDays(e.target.value)}
                >
                  {Object.entries(SORT_BY_TIME).map(([status, value]) => (
                    <MenuItem key={status} value={value} sx={{fontSize: 16}}>
                      {status}
                    </MenuItem>
                  ))}
                </TextField>
                <Button sx={{...dashStyles.button, ...dashStyles.blueButton,}}
                        onClick={() => navigate('/new-return')}
                >
                  New return
                </Button>
              </Box>
            </Box>
          </TabContext>
        </Box>
        {!loading ? <Box sx={{display: "flex", flexDirection: "column", width: "100%", marginTop: "5%"}}>
          {orders.map((order: any, index: any) => {
            const statusStyle = statusColor(order.order_status);
            return (
              <Box sx={index % 2 ? {...styles.orderBox, backgroundColor: customColors.lightGray} : {...styles.orderBox,}} key={index}
                   onClick={() => {
                     setCurrentOrder(order);
                     setModalOpen(true);
                   }}>
                <Box sx={{width: "50%", display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                  <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", width: "200px"}}>
                    <Box sx={{display: "flex", flexDirection: "row", height: "24px", alignItems: "center", }}>
                      <Typography
                        sx={{
                          borderRadius: "10px",
                          padding: "4px 8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          marginRight: "10px",
                          minHeight: "16px",
                          ...statusStyle
                        }}
                      >{order.order_status}</Typography>
                    </Box>
                    <Box sx={{display: "flex"}}>
                      <Typography sx={{fontSize: 16, marginRight: "5px", color: customColors.darkGray}}>№{order.order_id}</Typography>
                      <Typography sx={{fontSize: 16}}>{parseUTC(order.order_created_at)}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "75px"}}>
                    <Typography sx={{height: "10%", fontSize: 12, color: customColors.darkGray}}>From</Typography>
                    {
                      order.retailer_logo_path ? <CardMedia
                          sx={{
                            width: "72px",
                            height: "56px",
                            borderRadius: "16px",
                          }}
                          image={order.retailer_logo_path}
                        /> :
                        <Typography sx={{width: "72px",
                          height: "80px",}} variant="subtitle1">{order.retailer_name}</Typography>
                    }
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "155px"}}>
                    <Typography sx={{height: "10%", fontSize: 12, color: customColors.darkGray}}>Delivery</Typography>
                    <Typography sx={{fontSize: 16}}>{parseUTC(order.order_updated_at)}</Typography>
                  </Box>
                </Box>
                <Box sx={{width: "45%", display: "flex", flexDirection: "row",}}>
                  {
                    order.list_of_products.map((product: any) => (
                      <CardMedia
                        sx={{
                          width: "120px",
                          height: "88px",
                          borderRadius: "16px",
                          marginRight: "10px"
                        }}
                        image={product.picture_url ? product.picture_url : require("./src/PH.png")}
                        key={product.product_id}
                      />
                    ))
                  }
                </Box>
              </Box>
            )
          })}
          {
            !orders.length ?
              <Box sx={{display: "flex", width: "100%", justifyContent: "center"}}>
                <CardMedia
                  sx={{
                    height: "204px",
                    width: "402px",
                  }}
                  image={require("../LandingPage/src/NoOrder.png")}
                />
              </Box>
              : null
          }
          {
            showMore ? (
              <Box sx={{display: "flex", justifyContent: "center", width: "100%",
              }}>
                <Button sx={{backgroundColor: "black", color: "white", borderRadius: "32px",
                  paddingLeft: "10px", paddingRight: "10px", marginTop: "20px",
                  minHeight: "50px", minWidth: "150px",
                  "&:hover": {
                    backgroundColor: "black", color: "white",
                  }}}
                  onClick={handleShowMore}
                >Show More</Button>
              </Box>
            ) : null
          }
        </Box>: <Loader />}
      </Box>

    </Box>
    </Box>
  )
}