export const customColors = {
  primary: '#FDDD16',
  secondary: '#FFE543',
  gray: '#AEB4BB',
  darkGray: '#79838A',
  lightGray: '#F7F8FA',
  black: '#333',
  white: '#fff',
  purple: '#AB56FF',
  darkPurple: '#8F25F9',
  lightPurple: '#BC78FF',
  error: '#a7405a',
  warning: '#ff9100',
  advisory: '#fdd835',
  informational: '#0085B4',
  backgroundPrimary: '#f5f9fc',
  textLightSecondary: '#b1b3b5',
  textDarkSecondary: '#707070',
  textSubTitle: '#666666',
  green: '#0CCF71',
  darkGreen: '#0EA35B',
  lightGreen: '#1CF5C1',
  blue: '#29A4FD'
};

export default {
  initial: {
    primary: customColors.black,
  },

  primary: {
    main: customColors.primary,
  },

  secondary: {
    main: customColors.secondary,
  },

  textPrimary: {
    main: customColors.black,
  },

  textSecondary: {
    main: customColors.textLightSecondary,
  },

  error: {
    main: customColors.error,
  },

  info: {
    main: customColors.green
  },

  textDarkSecondary: {
    main: customColors.textDarkSecondary,
  },

  text: {
    primary: customColors.black,
    secondary: customColors.gray,
    white: customColors.white,
  },

  background: {
    paper: customColors.white,
    default: customColors.backgroundPrimary,
  },

  textSubTitle: {
    main: customColors.textSubTitle,
  },

};
