import { Box, CardMedia, MenuItem, Modal, TextField, Typography } from '@mui/material';
import styles from "../src/styles";
import { customColors } from '../../../theme/palette';
import { parseUTC } from '../../../utils/utc-to-date';
import { useEffect, useState } from 'react';
import { DEFAULT_PRICE_OBJECT, statusColor } from '../constants';
import StyledButton from '../../UI/StyledButton';
import { DashboardService } from '../../../api/services/dashboard-service';
import { useActions } from '../../../hooks/useHooks';
import { UserService } from '../../../api/services/user-service';
import Loader from '../../LandingPage/components/Loader';
import { useNavigate } from 'react-router-dom';

const styleForStatus = (status: string) => {
  switch (status) {
    case 'Buying tryon': case 'Keeping paid': case 'paid': {
      return {
        border: `2px solid ${customColors.green}`,
      }
    }
    default: {
      return {
        border: `2px solid ${customColors.primary}`,
      }
    }
  }
}

export default ({modalTrigger, setModalTrigger, order, returnReasons, }: any) => {
  const [payObject, setPayObject] = useState(DEFAULT_PRICE_OBJECT);
  const [products, setProducts]: any = useState({});
  const [statuses, setStatuses]: any = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {showErrorMessage, showSuccessMessage} = useActions();
  useEffect(() => {
    order.list_of_products.map((product: any) => {
      setStatuses((prevState: any) => ({
        ...prevState,
        [product.product_id]: product.product_status
      }));
    });
    if (order.order_status && order.order_status.includes('Delivered')) {
      order.list_of_products.map((product: any) => {
        const result: any = {
          product,
          return: false,
          returnReason: `Keep`
        }
        setProducts((prevState: any) => ({
          ...prevState,
          [product.product_id]: result
        }))
      });
      const email = localStorage.getItem(`email`);
      if (email) {
        DashboardService.clientCalculateSubmittionCharge(email, order.order_id)
          // @ts-ignore
          .then((res: any) => {
            const {status, details, ...left}: any = res;
            setPayObject(left);
          })
      }
    }
  }, [order]);

  const handleReturnReasonChange = async (returnReason: string, product_id: string) => {
    setProducts((prevState: any) => {
      return {
        ...prevState,
        [product_id]: {
          ...prevState[product_id],
          returnReason,
        },
      };
    });
    const email = localStorage.getItem(`email`);
    if (email) {
      if (returnReason === 'Keep') {
        const resp = await DashboardService.resetProductReturnReason(email, product_id,);
        if (!resp.status || resp.status !== '200') {
          showErrorMessage();
          return;
        }
      } else {
        const resp = await DashboardService.setProductReturnReason(email, product_id, returnReason);
        if (!resp.status || resp.status !== '200') {
          showErrorMessage();
          return;
        }
      }
      const res = await DashboardService.clientCalculateSubmittionCharge(email, order.order_id);
      if (!res.status || res.status !== '200') {
        showErrorMessage();
        return;
      }
      const {status, details, ...left}: any = res;
      const productStatuses: any = details.reduce((prevState: any, detail: any, ) => {
        return {
          ...prevState,
          [detail.product_id]: detail.product_status
        }
      }, {});
      setStatuses((prevState: any) => ({
        ...prevState,
        ...productStatuses,
      }));
      setPayObject(left);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const email = localStorage.getItem(`email`);
    if (email) {
      const returnResult: any = {};
      let productNumber = 1;
      for (const [id, value] of Object.entries(products)) {
        const {returnReason, product}: any = value;
        if (returnReason !== 'Keep') {
          const resp = await DashboardService.setProductReturnReason(email, id, returnReason);
          if (!resp.status || resp.status !== '200') {
            showErrorMessage();
            return;
          }
          returnResult.order_type = "return";
          returnResult.recipient_email = email;

          returnResult.first_destination_address1 = order.pickup_address1;
          returnResult.first_destination_address2 = order.pickup_address2;
          returnResult.first_destination_city = order.pickup_city;
          returnResult.first_destination_state = order.pickup_state;
          returnResult.first_destination_country = order.pickup_country;
          returnResult.first_destination_zip = order.pickup_zip;
          returnResult.first_destination_details = order.pickup_location_details;

          returnResult.second_destination_address1 = order.dropoff_address1;
          returnResult.second_destination_address2 = order.dropoff_address2;
          returnResult.second_destination_city = order.dropoff_city;
          returnResult.second_destination_state = order.dropoff_state;
          returnResult.second_destination_country = order.dropoff_country;
          returnResult.second_destination_zip = order.dropoff_zip;
          returnResult.second_destination_details = order.dropoff_location_details;

          returnResult[`product${productNumber}_name`] = product.product_name;
          returnResult[`product${productNumber}_description`] = product.product_description;
          returnResult[`product${productNumber}_price`] = product.product_price;
          returnResult[`product${productNumber}_weight`] = product.product_weight;
          returnResult[`product${productNumber}_dimentions_height`] = product.product_dimentions_height;
          returnResult[`product${productNumber}_dimentions_width`] = product.product_dimentions_width;
          returnResult[`product${productNumber}_dimentions_length`] = product.product_dimentions_length;
          returnResult[`product${productNumber}_dimentions_picture_url`] = product.picture_url;

          productNumber++;
        }
      }
      const completeResp = await DashboardService.submitOrder(email, order.order_id);
      if (completeResp.status === '200' && completeResp.message === "order_successfully_submitted" ) {
        const createOrderResp = await UserService.createReturn(email, returnResult);
        if (createOrderResp.status !== "200") {
          showErrorMessage();
        } else {
          document.location.reload();
        }
      } else {
        showErrorMessage(completeResp.message);
      }
    }
  };

  const statusStyle = statusColor(order.order_status);
  return (
    <Modal
      open={modalTrigger}
      onClose={() => setModalTrigger(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display:'flex',alignItems:'center',justifyContent:'center', overflow:'scroll',}}
    >
      <Box sx={{ ...styles.modal, width: "50%", minHeight: "400px", padding: "20px"}}>
        <Box sx={{...styles.insideModalBox}}>
          <Box sx={{display: "flex", flexDirection: "row", width: "100%", marginBottom: "2%", marginTop: "2%"}}>
            <Typography variant="h2">
              Order
            </Typography>
            <Typography variant="h2" sx={{color: customColors.gray, marginLeft: "1%"}}>
              • {order.order_id}
            </Typography>
          </Box>
          <Box sx={{}}>
            <Box sx={{...styles.orderDetailsRow}}>
              <Typography variant="body1" sx={{...styles.orderDetailName}}>
                Order
              </Typography>
              <Typography
                sx={{
                  borderRadius: "10px",
                  padding: "4px 8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  marginRight: "10px",
                  minHeight: "16px",
                  ...statusStyle
                }}
              >{order.order_status}</Typography>
              <Typography variant="subtitle1" sx={{color: customColors.darkGray}}>
                {parseUTC(order.order_created_at)}
              </Typography>
            </Box>
            <Box sx={{...styles.orderDetailsRow}}>
              <Typography variant="body1" sx={{...styles.orderDetailName}}>
                Retailer
              </Typography>
              <Typography variant="subtitle1" sx={{color: customColors.black}}>
                {order.retailer_name}
              </Typography>
            </Box>
            <Box sx={{...styles.orderDetailsRow}}>
              <Typography variant="body1" sx={{...styles.orderDetailName}}>
                Destination
              </Typography>
              <Box sx={{minHeight: "60px", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                <Typography variant="subtitle1" sx={{color: customColors.black}}>
                  {order.pickup_address1}
                </Typography>
                <Typography variant="subtitle1" sx={{color: customColors.primary}}>
                  {parseUTC(order.order_updated_at)}
                </Typography>
              </Box>
            </Box>
            {
              order.direver_first_name ?
              <Box sx={{ ...styles.orderDetailsRow }}>
                <Typography variant='body1' sx={{ ...styles.orderDetailName }}>
                  Driver
                </Typography>
                <Typography variant='subtitle1' sx={{ color: customColors.black }}>
                  {order.direver_first_name} {order.direver_last_name}, {order.vehicle_make} {order.vehicle_model} {order.vehicle_plate}
                </Typography>
              </Box>
                : order.direver_return_first_name ?
                <Box sx={{ ...styles.orderDetailsRow }}>
                  <Typography variant='body1' sx={{ ...styles.orderDetailName }}>
                    Driver
                  </Typography>
                  <Typography variant='subtitle1' sx={{ color: customColors.black }}>
                    {order.direver_return_first_name} {order.direver_return_last_name}, {order.vehicle_make_return} {order.vehicle_model_return} {order.vehicle_plate_return}
                  </Typography>
                </Box>
                : null
            }
            {order.order_details ? <Box sx={{...styles.orderDetailsRow, marginBottom: "2%",}}>
              <Typography variant="body1" sx={{...styles.orderDetailName}}>
                Details
              </Typography>
              <Typography variant="subtitle1" sx={{color: customColors.black}}>
                {order.order_details}
              </Typography>
            </Box> : null}
          </Box>
          <Box sx={{marginBottom: "2%"}}>
            {order.list_of_products.map((product: any) => {
              const productStatusColor = styleForStatus(statuses[product.product_id]);
              return (
                <Box sx={{display: "flex", width: "100%", flexDirection: "column"}}>
                  <Box sx={{...styles.productDetail}} key={product.product_id}>
                    <CardMedia
                      sx={{
                        width: "160px",
                        height: "120px",
                        borderRadius: "16px",
                        marginRight: "10px"
                      }}
                      image={product.picture_url ? product.picture_url : require("../src/PH.png")}
                      key={product.product_id}
                    />
                    <Box sx={{width: "60%"}}>
                      <Typography variant="subtitle1">
                        {product.product_name}
                      </Typography>
                      <Typography variant="subtitle2" sx={{color: customColors.darkGray}}>
                        {product.product_description}
                      </Typography>
                    </Box>
                    <Box sx={{display: "flex", minWidth: "190px", flexDirection: "column"}}>
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "7px"}}>
                        <Typography sx={{
                          borderRadius: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4px 10px",
                          fontSize: 12,
                          ...productStatusColor,
                        }}>
                          {statuses[product.product_id]}
                        </Typography>
                        <Typography variant="subtitle1" sx={{marginLeft: "10px", padding: "4px"}}>
                          $ {product.product_price}
                        </Typography>
                      </Box>
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", borderRadius: "16px", backgroundColor: customColors.lightGray, minHeight: "55px", justifyContent: "space-around"}}>
                        <Box sx={{width: "30%", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                          <Typography variant="subtitle2" sx={{color: customColors.darkGray}}>
                            Weight
                          </Typography>
                          <Typography variant="subtitle1" sx={{display: "flex"}}>
                            {product.product_weight}
                            <Typography variant="subtitle1" sx={{color: customColors.darkGray, display: "flex", marginLeft: "4px"}}>lb</Typography>
                          </Typography>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                          <Typography variant="subtitle2" sx={{color: customColors.darkGray}}>
                            Dimensions
                          </Typography>
                          <Typography variant="subtitle1" sx={{display: "flex"}}>
                            <Typography variant="subtitle1" sx={{color: customColors.darkGray, display: "flex", }}>H</Typography>
                            {product.product_dimentions_height}
                            <Typography variant="subtitle1" sx={{color: customColors.darkGray, display: "flex", marginLeft: "4px",}}>W</Typography>
                            {product.product_dimentions_width}
                            <Typography variant="subtitle1" sx={{color: customColors.darkGray, display: "flex", marginLeft: "4px",}}>L</Typography>
                            {product.product_dimentions_length}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  { order.order_status && order.order_status.includes('Delivered') && !order.order_submitted_at ? (
                    <Box sx={{display: "flex", flexDirection: 'row', width: '100%', marginTop: "5px"}}>
                      <TextField
                        select
                        sx={{...styles.dropDownInput, ...styles.cardInput, ...styles.input, width: "40%"}}
                        label="Pick up return reason"
                        defaultValue='Keep'
                        onChange={e => handleReturnReasonChange(e.target.value, product.product_id)}
                      >
                        <MenuItem key={'Keep'} value={'Keep'} sx={{fontSize: 16}}>
                          Keep
                        </MenuItem>
                        {
                          returnReasons.map((ret: any) => {
                            const { reason } = ret;
                            return (
                              <MenuItem key={reason} value={reason} sx={{fontSize: 16}}>
                                {reason}
                              </MenuItem>
                            )
                          })
                        }
                      </TextField>
                    </Box>
                  ) : null }
                </Box>
              )
            })}
          </Box>
        { order.order_status && order.order_status.includes('Delivered') && !order.order_submitted_at ?
        <form onSubmit={handleSubmit}>
          {!loading ? <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Box sx={{
              backgroundColor: '#2D3033',
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingTop: '15px',
              paddingBottom: '15px',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Box sx={{ ...styles.orderPaymentRow }}>
                <Typography variant='subtitle1' sx={{ color: customColors.gray }}>
                  Already paid in store
                </Typography>
                <Typography variant='subtitle1' sx={{ color: customColors.gray }}>
                  $ {payObject.total_paid_in_store}
                </Typography>
              </Box>
              <Box sx={{ ...styles.orderPaymentRow }}>
                <Typography variant='subtitle1' sx={{ color: customColors.white }}>
                  Refund
                </Typography>
                <Typography variant='subtitle1' sx={{ color: customColors.white }}>
                  $ {payObject.total_to_refund}
                </Typography>
              </Box>
              {/*<Box sx={{...styles.orderPaymentRow}}>*/}
              {/*  <Typography variant="subtitle1" sx={{color: customColors.white}}>*/}
              {/*    Return service*/}
              {/*  </Typography>*/}
              {/*  <Typography variant="subtitle1" sx={{color: customColors.white}}>*/}
              {/*    /!*$ {payObject.returnService}*!/*/}
              {/*  </Typography>*/}
              {/*</Box>*/}
              <Box sx={{ ...styles.orderPaymentRow }}>
                <Typography variant='subtitle1' sx={{ color: customColors.white }}>
                  To pay now
                </Typography>
                <Typography variant='subtitle1' sx={{ color: customColors.white }}>
                  $ {payObject.total_to_pay_now}
                </Typography>
              </Box>
            </Box>
            <StyledButton text='Complete order' type='submit' sx={{ marginTop: '20px', minWidth: '240px' }} />
          </Box> : <Loader />}
        </form> : null}
        </Box>
      </Box>
  </Modal>
  )
}