import axios from 'axios';
import api from '../api';

const baseUrl = `https://api.goflyy.com/v1`;

export class SignService {
  static async validateZip (zip: string) {
    const URL = `/isZipAllowed`;
    const formData = new FormData();
    formData.append('zip', zip);
    const resp = await axios.post(baseUrl + URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  }

  static async createAccount(email: string, password: string) {
    const URL = `/createAccount`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('account_type', 'client');
    const resp = await axios.post(baseUrl + URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  }

  static async resetPassword(email: string) {
    const URL = `/resetPassword`;
    const formData = new FormData();
    formData.append('email', email);
    const resp = await axios.post(baseUrl + URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  }

  static async verifyEmail(email: string, code: string) {
    const URL = `/verifyEmail`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('code', code);
    const resp = await axios.post(baseUrl + URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  }



  static async login(email: string, password: string) {
    const URL = `/login`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    const resp = await axios.post(baseUrl + URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  }

  static async addWaitingList(email: string, zip: string) {
    const URL = `/addWaitingList`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('zip', zip);
    formData.append('type', 'client');
    const resp = await axios.post(baseUrl + URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  }

  static async logout(email: string) {
    const URL = `/logout`;
    const formData = new FormData();
    formData.append('email', email);
    const resp = await axios.post(baseUrl + URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return resp;
  }
}
