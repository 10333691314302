import DashboardTypes from '../dashboard/types';
import { DashboardService } from '../../api/services/dashboard-service';

export const getOrdersList = (filters?: any) => async (dispatch: any) => {
  const email = localStorage.getItem('email');
  if (email) {
    const data = await DashboardService.getOrdersIdsList(email, filters);
    if (data.length > 5) {
      dispatch({
        type: DashboardTypes.SET_SHOW_MORE,
        payload: true,
      });
    }
    const ordersIdsList = data.slice(0, 5);
    dispatch({
      type: DashboardTypes.SET_ORDERS_ID_LIST,
      payload: ordersIdsList,
    });
    const orders: any = [];
    for (const id of ordersIdsList) {
      const {status, data: orderData}: any = await DashboardService.getOrder(email, id.order_id);
      if (status === 200 && orderData.length && !orderData.message) {
        orders.push(orderData[0]);
      }
    }
    dispatch({
      type: DashboardTypes.SET_ORDERS,
      payload: orders,
    });
    return orders;
  }
};

export const getOrdersListWithPage = (page: number) => async (dispatch: any) => {
  dispatch({
    type: DashboardTypes.SET_SHOW_MORE,
    payload: false,
  });
  const email = localStorage.getItem('email');
  if (email) {
    const data = await DashboardService.getOrdersIdsList(email, {
      skip: String(page * 5)
    });
    const ordersIdsList = data.slice(0, 5);
    dispatch({
      type: DashboardTypes.ADD_ORDERS_ID_TO_LIST,
      payload: ordersIdsList,
    });
    const orders: any  = [];
    for (const id of ordersIdsList) {
      const {status, data: orderData} = await DashboardService.getOrder(email, id.order_id);
      if (status === 200 && orderData.length && !orderData.message) {
        orders.push(orderData[0]);
      }
    }
    dispatch({
      type: DashboardTypes.ADD_ORDERS,
      payload: orders,
    });
    if (data.length > 5) {
      dispatch({
        type: DashboardTypes.SET_SHOW_MORE,
        payload: true,
      });
    }
    return orders;
  }
};