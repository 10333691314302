import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton, Link,
  Menu,
  MenuItem, Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import palette, { customColors } from '../../theme/palette';
import { ReactComponent as Apple } from "../LandingPage/src/assets/Apple.svg";
import { ReactComponent as GooglePlay } from "../LandingPage/src/assets/GP.svg";
import { ReactComponent as Windows } from "../LandingPage/src/assets/Windows.svg";
import styles from "../LandingPage/src/styles";
import { LandingService } from '../../api/services/lading-service';
import { LpDataParser } from '../LandingPage/src/lpDataParser';

const style = {
  appBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: customColors.white,
    justifyContent: "space-between",
    boxShadow: "0px 0px",
    padding: "50px",
    alignItems: "center",
    paddingRight: "110px",
    ['@media (max-width:1300px)']: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: "10px",
      paddingRight: "0px",
    },
  },
}

export default () => {
  const [links, setLinks]: any = useState({
    TOS: ``,
    PP: ``,
    OG: ``,
  });

  useEffect (() => {
    (async () => {
      try {
        const data = await LandingService.getLanding();
        if (data) {
          const {docLinks} = LpDataParser(data);
          setLinks((prevState: any) => ({...prevState, ...docLinks}));
        }
      } catch (e) {
        console.log(e);
      }
    })()
  }, []);
  return (
    <AppBar sx={{ ...style.appBar }} position="static">
      {/*<Box sx={{['@media (max-width:1300px)']: {*/}
      {/*    marginBottom: "10px"*/}
      {/*  }}}>*/}
      {/*  <Button>*/}
      {/*    <Apple style={{...styles.imageIcon}}/>*/}
      {/*  </Button>*/}
      {/*  <Button>*/}
      {/*    <GooglePlay style={{...styles.imageIcon}}/>*/}
      {/*  </Button>*/}
      {/*  <Button>*/}
      {/*    <Windows style={{...styles.imageIcon}}/>*/}
      {/*  </Button>*/}
      {/*</Box>*/}
      <Typography variant="subtitle2" sx={{fontWeight: "bold", ['@media (max-width:1300px)']: {
          marginBottom: "10px"
        }}}>
        © GoFlyy, 2022
      </Typography>
      <Box sx={{['@media (max-width:1300px)']: {
          marginBottom: "10px"
        }}}>
        <Link href={links.TOS} sx={{marginRight: "10px", color: customColors.black}} target="_blank">Terms of Service</Link>
        <Link href={links.PP} sx={{marginRight: "10px", color: customColors.black}} target="_blank">Privacy Policy</Link>
        <Link href={links.OG} sx={{color: customColors.black}} target="_blank">Offer Agreement</Link>
      </Box>
      <Box sx={{['@media (max-width:1300px)']: {
          marginBottom: "10px"
        }}}>
        <Link href="https://driver.goflyy.com/" sx={{color: customColors.purple, fontWeight: "bold", marginRight: "10px", }} underline="hover" target="_blank">For Driver</Link>
        <Link href="https://retailer.goflyy.com/" sx={{color: customColors.purple, fontWeight: "bold", }} underline="hover" target="_blank">For Retailers</Link>
      </Box>
      <Typography variant="subtitle2">
        Design — <b>Atwinta</b>
      </Typography>
    </AppBar>
  )
}