import styles from '../src/styles';
import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';


export default () => (
  <Box sx={{...styles.contentBox,
    ['@media (max-width:1050px)']: {
      flexDirection: "column",
      alignItems: "center",
  },}} className="How it works">
    <Box sx={{...styles.innerBox, width: "25%",
      ['@media (max-width:1050px)']: {
        width: "30%",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "center",
        marginTop: "15px",
        minHeight: "10%"
      },
      ['@media (max-width:900px)']: {
        width: "70%",
      },}}>
      <Typography variant="h2" sx={{fontWeight: "bold", ...styles.resizeH2}}>
        How
      </Typography>
      <Typography variant="h2" sx={{fontWeight: "bold", ...styles.resizeH2}}>
        it works
      </Typography>
    </Box>
    <CardMedia sx={{
      width: "800px",
      height: "688px",
      ['@media (max-width:1450px)']: {
        width: "700px",
        height: "602px",
      },
      ['@media (max-width:750px)']: {
        width: "600px",
        height: "516px",
      },
      ['@media (max-width:650px)']: {
        width: "500px",
        height: "430px",
      },
      ['@media (max-width:550px)']: {
        width: "400px",
        height: "344px",
      },
      ['@media (max-width:450px)']: {
        width: "300px",
        height: "258px",
      },

    }} image={require("../src/HIW.png")} style={{}}/>
    {/*<Box sx={{...styles.innerBox, width: "70%", paddingLeft: "5%"}}>*/}
    {/*  <img src={HIW} style={{maxHeight: "1000px", maxWidth: "1000px"}}/>*/}
    {/*</Box>*/}
  </Box>
)