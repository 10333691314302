import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControlLabel, IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import styles from "./src/styles";
import React, { useState } from 'react';
import StyledButton from '../UI/StyledButton';
import { customColors } from '../../theme/palette';
import GFIcon from '../UI/GFIcon';
import Assets from './components/Assets';
import { useNavigate } from 'react-router-dom';
import { SignService } from '../../api/services/sign-service';
import { testEmail } from './constants';
import { useAuthRedirect } from '../../utils/use-auth';
import { CREATE_ACC_RESPONSES } from '../../api/constants';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useActions } from '../../hooks/useHooks';

const USER_TEXTS = {
  primary: `Before creating an account, let us check if GoFlyy services are available in your area.`,
  ZIP_FAIL: `Unfortunately, we are not servicing your area. Please leave your email, and we will notify you when GoFlyy comes to your location.`,
  ZIP_SUCCESS: `We are in your area! To continue, please complete form below.`,
  ACCOUNT_CREATED: `Account successfully created, please verify you email.`,
  EMAIL_TAKEN: `Email is already registered in GoFlyy service. Please use another email.`
};

export default () => {
  useAuthRedirect(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [underText, setUnderText] = useState(USER_TEXTS.primary);
  const [zip, setZip] = useState('');
  const [validatedZip, setValidatedZip] = useState(false);
  const [validZip, setValidZip] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {showErrorMessage, showSuccessMessage} = useActions();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    try {

      if (validatedZip) {
        if (validZip) {
          const {status, data} = await SignService.createAccount(email, pass);
          if (data.message === CREATE_ACC_RESPONSES.SUCCESS) {
            setRegistered(true);
            setUnderText(USER_TEXTS.ACCOUNT_CREATED);
          } else if (data.message === CREATE_ACC_RESPONSES.EMAIL_ALREADY_TAKEN) {
            setUnderText(USER_TEXTS.EMAIL_TAKEN);
          }
        } else {
          const {data} = await SignService.addWaitingList(email, zip);
          if (data.status === "200" && data.message === "record_successfully_added") {
            showSuccessMessage();
          }
        }
      } else {
        const {data} = await SignService.validateZip(zip);
        if (data.message) {
          setUnderText(USER_TEXTS.ZIP_SUCCESS);
          setValidZip(true);
        } else {
          setUnderText(USER_TEXTS.ZIP_FAIL);
          setValidZip(false);
        }
        setValidatedZip(true);
      }
    } catch (e) {
      showErrorMessage();
    }
  };
  return (
    <Box sx={{...styles.generalBox}}>
      <Box sx={{...styles.contentBox}}>
        <Button sx={{marginTop: "40px"}} onClick={() => navigate('/')}>
          <GFIcon />
        </Button>
        <Box sx={{...styles.loginBox, paddingTop: "20px", paddingBottom: "20px"}}>
          <form onSubmit={handleSubmit} style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}>
            <Typography sx={{fontSize: 32, marginBottom: "20px", fontWeight: 'bold'}}>Sign up to GoFlyy</Typography>
            <Typography variant="subtitle1" sx={registered ? {color: customColors.green} : {color: customColors.black}}>
              {underText}
            </Typography>
            {!registered &&
              (
                <>
                <TextField required type="number" label="ZIP" variant="outlined" sx={{...styles.input, marginTop: "20px",
                  background: (validatedZip ? (validZip ? `rgba(28,245,193,.2)` : `rgba(250,70,81,.2)`) : customColors.lightGray),
                  borderRadius: "16px"
                }} onChange={e => setZip(e.target.value)}/>
                {validatedZip && <TextField required label="Email address" variant="outlined" sx={{...styles.input,}} onChange={e => {
                  setEmail(e.target.value);
                  setValidEmail(testEmail(e.target.value));
                }}/>}
                {validatedZip && validZip &&
                <TextField type={showPassword ? "text" : "password"} required label="Password" variant="outlined" sx={{...styles.input, marginTop: "10px"}} onChange={e => setPass(e.target.value)}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="toggle password visibility"
                         onClick={handleClickShowPassword}
                         onMouseDown={handleMouseDownPassword}
                       >
                         {showPassword ? <Visibility /> : <VisibilityOff />}
                       </IconButton>
                     </InputAdornment>
                   )
                 }}
                />
                }
                {validatedZip && validZip &&
                <TextField type={showPassword ? "text" : "password"} required label="Repeat Password" variant="outlined" sx={{...styles.input, marginTop: "10px"}} onChange={e => setRepeatPass(e.target.value)}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="toggle password visibility"
                         onClick={handleClickShowPassword}
                         onMouseDown={handleMouseDownPassword}
                       >
                         {showPassword ? <Visibility /> : <VisibilityOff />}
                       </IconButton>
                     </InputAdornment>
                   )
                 }}
                />
                }
                
                {validatedZip && validZip &&
                  <FormControlLabel sx={{textAlign: "start", marginBottom: "20px"}} control={<Checkbox />} label="I read and agree with the Terms of Services." onChange={() => setAccepted(prevState => !prevState)} />
                }
                
                <Box sx={{...styles.buttonBox, justifyContent: "center"}}>
                  {!validatedZip && <StyledButton onClick={() => {
                  }} text='Check' type='submit' sx={{ ...styles.signUpButtons }}
                                                  disabled={!(zip.length >= 5) || !(zip.length <= 10)} />}
                  {validatedZip && !validZip && <StyledButton onClick={() => {
                  }} text='Send' type='submit' sx={{ ...styles.signUpButtons }}
                                                              disabled={!(zip.length >= 5) || !(zip.length <= 10) || !validEmail} />}
                  {validatedZip && validZip && <StyledButton onClick={() => {
                  }} text='Create account' type='submit' sx={{ ...styles.signUpButtons }}
                                                        disabled={!(repeatPass === pass) || !validEmail || !accepted} />}
                  <Button onClick={() => navigate('/login')} variant="outlined" sx={{height: "50px", width: "45%", borderRadius: "20px", color: customColors.black, ['@media (max-width:640px)']: {
                      width: "100%",
                      maxWidth: "100%"
                    },}}>
                    Back to log in
                  </Button>
                </Box>
                </>
              )}
          </form>
        </Box>
      </Box>
    </Box>
  )
}