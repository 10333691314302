import api from '../api';
import { updateUserBody } from '../../utils/update-user-body';
import axios from 'axios';
import { apiRootUrl } from '../constants';

export class UserService {
  static async getProfile (email: string) {
    const URL = `/client/getProfile`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async updateUser(body: any, email: string) {
    const {address: addressBody, phone: phoneBody, contact: contactBody} = updateUserBody(body);
    const address = Object.entries(addressBody);
    const phone = Object.entries(phoneBody);
    const contact = Object.entries(contactBody);
    if (address.length) {
      const formData = new FormData();
      formData.append('email', email);
      const URL = '/addAddress';
      for (const [key, value] of address) {
        formData.append(key, value);
      }
      const resp = await api.post(URL, formData);
      // smth with resp here
    }
    if (phone.length) {
      const formData = new FormData();
      formData.append('email', email);
      const URL = '/addPhone';
      for (const [key, value] of phone) {
        formData.append(key, value);
      }
      const resp = await api.post(URL, formData);
      // smth with resp here
    }
    if (contact.length) {
      const formData = new FormData();
      formData.append('email', email);
      const URL = '/addContact';
      for (const [key, value] of contact) {
        formData.append(key, value);
      }
      const resp = await api.post(URL, formData);
      // smth with resp here
    }
  }

  static async getNotifications(email: string) {
    const URL = `/getNotificationsStatus?email=${email}`;
    const {data} = await api.get(URL);
    return data;
  }

  static async updateNotifications(body: any, userEmail: string) {
    const URL = '/updateNotifications';
    const token = localStorage.getItem('token');
    const settingsArray = Object.entries(body);
    const settings: any = {};
    settingsArray.map(([key, value]: any) => {
      const {email, txt, push} = value;
      settings[key] = {
        email,
        txt,
        push
      }
    });
    const reqBody = {
      email: userEmail,
      settings,
    };
    const {data} = await axios.post(apiRootUrl + URL, reqBody, {
      headers: {
        token: token ? token : '',
        "Content-Type": 'application/json'
      }
    });
    return data;
  }

  static async getReturnReasonsList(email: string) {
    const URL = `/client/getReturnReasonsList`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getListOfAllRetailers(email: string) {
    const URL = `/client/getListOfAllRetailers`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getOneTimeApiKey(email: string) {
    const URL = `/client/generateOneTimeApiKey`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async createReturn(email: string, body: any) {
    const URL = `/ecommerce/createOrder`;
    const formData = new FormData();
    const {apikey_onetime} = await UserService.getOneTimeApiKey(email);
    formData.append('email', email);
    const bodyData: any = Object.entries(body);
    for (const [key, value] of bodyData) {
      formData.append(key, value);
    }
    const {status, data} = await axios.post(apiRootUrl + URL, formData, {
      headers: {
        "Content-Type":  "multipart/form-data",
        apikey: apikey_onetime
      }
    });
    return data;
  }

  static async addCard(email: string, body: any) {
    const URL = `/addCard`;
    const formData = new FormData();
    formData.append('email', email);
    const bodyData: any = Object.entries(body);
    for (const [key, value] of bodyData) {
      formData.append(key, value);
    }
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async verifyPhone(email: string, code: string, phone: string, country_code: string) {
    const URL = `/verifyPhone`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('code', code);
    formData.append('phone', phone);
    formData.append('country_code', country_code);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getTransactions (email: string, skip: number, limit = 10, ) {
    const URL = `/getTransactions`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('limit', `${limit + 1}`);
    formData.append('skip', `${skip}`);
    const {data} = await api.post(URL, formData);
    const result = {
      data,
      showMore: data.length > limit
    }
    return result;
  }

  static async getTransactionSummary (email: string ) {
    const URL = `/client/getTransactionSummary`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }



}
