import { Box, ThemeProvider } from '@mui/material';
import { lightTheme } from './theme';
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter, } from "react-router-dom";
import { PublicRoutes } from './routes/publicRoutes';
import { PublicRoute } from './routes/checkRoutes';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import DashboardHeader from './components/Header/DashboardHeader';
import { useMemo, useState } from 'react';
import AuthContext from './context/authContext';
import { Provider } from 'react-redux';
import { store } from './redux';
import { AppSnackbar } from './components/Snackbar/Snackbar';

const App = () => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token'),
  );
  const [email, setEmail] = useState<string | null>(
    localStorage.getItem('email'),
  );

  window.addEventListener('storage', () => {
    const storeEmail = localStorage.getItem('email');
    const storeToken = localStorage.getItem('token');
    if (!storeEmail || !storeToken) {
      setToken(null);
      setEmail(null);
    }
  });

  const signRoutes = ['/login', '/registration', '/reset-password', '/confirmEmail'];
  const dashRoutes = ['/profile', '/dashboard', '/new-return', '/support',];
  const authContext = useMemo(() => {
    return {
      token,
      setToken,
      email,
      setEmail,
    };
  }, [token, email]);
  return (
    <Provider store={store}>
    <AuthContext.Provider value={authContext}>
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
            <PublicRoutes />
            {/*{publicRoutes.map((route) => (*/}
            {/*  <Route key={route.path} path={route.path} element={*/}
            {/*    <PublicRoute {...route} />*/}
            {/*  } />*/}
            {/*))}*/}

            {/*<Navigate to={'/dashboard'} />*/}
          <AppSnackbar />
          { !(signRoutes.includes(window.location.pathname)) ?
            <Footer /> :
            null
          }
        </BrowserRouter>
      </ThemeProvider>
    </Box>
    </AuthContext.Provider>
    </Provider>
  );
};

export default App;
