import { Box, Button, CardMedia, Typography } from '@mui/material';
import StyledButton from '../../UI/StyledButton';
import styles from '../../Dashboard/src/styles';
import { customColors } from '../../../theme/palette';
import dashStyles from '../../Dashboard/src/styles';
import landStyles from '../../LandingPage/src/styles';
import Balance from '../../Dashboard/components/Balance';
import { useActions, useUser } from '../../../hooks/useHooks';
import { useState } from 'react';
import CardModal from './CardModal';
import { useNavigate } from 'react-router-dom';
import { SignService } from '../../../api/services/sign-service';

const today = new Date();
const TEST_CARD = {
  last4: "",
  cvc: "",
  // type: "",
  // provider_name: "",
  // name_oncard: "",
  exp_month: `${today.getUTCMonth() + 1}`,
  exp_year: `${today.getUTCFullYear()}`,
  // zip: "",
  number: ``,
  logo_url: ``,
}

export default () => {
  const { userDraft } = useUser();
  const navigate = useNavigate();
  const { creditcards } = userDraft;
  const [isModalOpen, setModalOpen] = useState(false);
  const {clearUserDraft} = useActions();
  const lastCard = (creditcards && creditcards.length) ? creditcards[creditcards.length - 1] : TEST_CARD;

  // lastCard.zip = creditcards.length ? lastCard.zip : userDraft.zip;
  // lastCard.name_oncard = creditcards.length ? lastCard.name_oncard : userDraft.first_name;
  return (
    <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
      <CardModal modalTrigger={isModalOpen} setModalTrigger={setModalOpen} currentCard={lastCard} />
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", ...landStyles.defaultMargin,
        ['@media (max-width:1000px)']: {
          flexDirection: "column",
        },
      }}>
        <Box sx={{display: "flex", flexDirection: "row", width: "80%"}}>
          { creditcards && creditcards.length ? <Box sx={{ ...styles.balance }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '80%',
              height: '40%',
              alignItems: 'center',
            }}>
              <CardMedia
                sx={{width: "120px", height: "60px"}}
                image={lastCard.logo_url}
              />
              <Typography variant='subtitle1'>
                •• {lastCard.last4}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%', height: '40%' }}>
              <Typography color={customColors.primary} sx={{ fontSize: 12 }}>Address for sending receipts</Typography>
              <Typography variant='subtitle1'>{userDraft.address1}</Typography>
            </Box>
          </Box>
            :
            <StyledButton text="Add bank card"
            sx={{height: "56px", width: "260px", maxWidth: "260px",}}
            onClick={() => setModalOpen(true)}
            />
          }
        </Box>
        <Box sx={{
          display: "flex", flexDirection: "column",
          ['@media (max-width:1000px)']: {
            width: "80%",
          },
        }}>
          <StyledButton fullWidth sx={{maxWidth: "100%",
            borderRadius: "16px",
            ['@media (max-width:1000px)']: {
              width: "50%",
              minWidth: "280px"
            },
          }} text="Billing history"
          onClick={() => navigate('/billing-history')}
          />
          {creditcards && creditcards.length ? <Button variant='outlined' fullWidth sx={{
            borderRadius: '16px', padding: '10px', color: customColors.black, marginTop: '20px',
            ['@media (max-width:1000px)']: {
              width: '50%',
              minWidth: '280px',
            },
          }}
          onClick={() => setModalOpen(true)}
          disabled={true}
          >
            Change bank card
          </Button> : null}
        </Box>
      </Box>
      {/*<Box sx={{display: "flex", flexDirection: "row", maxWidth: "540px"}}>*/}
      {/*  <Button variant="outlined" sx={{...styles.buttons, color: customColors.black, borderColor: "red"}}*/}
      {/*          onClick={async () => {*/}
      {/*            const email = localStorage.getItem('email');*/}
      {/*            // @ts-ignore*/}
      {/*            await SignService.logout(email);*/}
      {/*            clearUserDraft();*/}
      {/*            localStorage.removeItem('token');*/}
      {/*            localStorage.removeItem('email');*/}
      {/*            navigate('/');*/}
      {/*          }}*/}
      {/*  >*/}
      {/*    Logout*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </Box>
  )
}