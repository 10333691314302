import { useAuthRedirect } from '../../utils/use-auth';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from "../Dashboard/src/styles";
import { useActions, useSupportTickets } from '../../hooks/useHooks';
import Loader from '../LandingPage/components/Loader';
import { useNavigate } from 'react-router-dom';
import { parseUTC } from '../../utils/utc-to-date';
import { customColors } from '../../theme/palette';
import DashboardHeader from '../Header/DashboardHeader';

export default () => {
  useAuthRedirect();
  const tickets = useSupportTickets();
  const navigate = useNavigate();
  const {getTickets, setCurrentTicket, showErrorMessage, showSuccessMessage, } = useActions();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      getTickets()
        // @ts-ignore
        .then(() => {
          setLoading(false);
        })
    } catch (e) {
      showErrorMessage();
    }
  }, []);

  return (
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
    <DashboardHeader />
    <Box sx={{ ...styles.box }}>
      <Box sx={{ ...styles.contentBox, }}>
        <Button sx={{...styles.button, ...styles.blueButton, width: "120px"}}
          onClick={() => navigate('/new-ticket')}
        >New ticket</Button>
        {
          !loading ?
           (
           <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
              <Box sx={{...styles.supportLine, }}>
                <Typography sx={{width: "6%"}} variant="subtitle1">№</Typography>
                <Typography sx={{width: "8%", minWidth: "70px"}} variant="subtitle1">Type</Typography>
                <Typography sx={{width: "20%"}} variant="subtitle1">Date</Typography>
                <Typography sx={{width: "40%"}} variant="subtitle1">Details</Typography>
                <Typography sx={{width: "6%", minWidth: "70px"}} variant="subtitle1">Status</Typography>
                <Typography sx={{width: "17%"}} variant="subtitle1">Status date</Typography>
              </Box>
             {
               tickets.length ? null :
                 <Box sx={{
                   marginTop: "30px",
                   width: "100%",
                   textAlign: "center"
                 }}>
                 <Typography variant="h2">
                   No tickets yet
                 </Typography>
                 </Box>
             }
             {
               tickets.map((ticket: any, index: any) => (
                 <Box sx={ !(index % 2) ? {...styles.supportLine, backgroundColor: customColors.lightGray } :  {...styles.supportLine}}
                      onClick={() => {
                        setCurrentTicket(ticket);
                        navigate(`/support/ticket?id=${ticket.ticket_id}`);
                      }}
                 >
                   <Typography sx={{width: "6%"}} variant="subtitle1">{ticket.ticket_count}</Typography>
                   <Typography sx={{width: "8%", minWidth: "70px"}} variant="subtitle1">{ticket.type}</Typography>
                   <Typography sx={{width: "20%"}} variant="subtitle1">{parseUTC(ticket.created_at)}</Typography>
                   <Typography sx={{width: "40%"}} variant="subtitle1">{ticket.details}</Typography>
                   <Typography sx={{width: "6%", minWidth: "70px"}} variant="subtitle1">{ticket.status}</Typography>
                   <Typography sx={{width: "17%"}} variant="subtitle1">{
                     ticket.updated_at ?
                       parseUTC(ticket.updated_at) :
                       parseUTC(ticket.created_at)
                   }</Typography>
                 </Box>
               ))
             }
           </Box>
           ) : <Loader />
        }

      </Box>
    </Box>
    </Box>
  )
}