import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem, Paper,
  Toolbar,
  Tooltip,
  Typography, useScrollTrigger,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useCallback } from 'react';
import GFIcon from '../UI/GFIcon';
import palette, { customColors } from '../../theme/palette';
import StyledButton from '../UI/StyledButton';
import { useNavigate } from 'react-router-dom';

// TODO: fix routing

const pagesWithLink = [
  {
    name: 'Benefits',
    link: ''
  },
  {
    name: 'How it works',
    link: ''
  },
  {
    name: 'About',
    link: '/about'
  },
  {
    name: 'Testimonials',
    link: ''
  },
  {
    name: 'FAQ',
    link: ''
  },
];

const linksByName: {[index: string]:any} = {
  About: '/about'
}

const settings = ['Profile', 'Dashboard', 'Logout'];

const styles = {
  appBar: {
    backgroundColor: customColors.white,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px"
  },
  appBarSticky: {
    background: "rgba(255, 255, 255, 0.9)",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 200,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px"
  },
  signUp: {
    color: customColors.black,
    backgroundColor: customColors.primary,
    marginLeft: "15px",
    width: "200px",
    borderRadius: "15px"
  },
  toolBar: {
      display: "flex",
      alignContent: "center",
      justifyContent: "space-between",
      flexDirection: "row"
  },
  cont: {
    marginLeft: "1%",
    marginRight: "1%",
    maxWidth: "100%",
    ['@media (max-width:1536px)']: {
      maxWidth: "90%",
      marginLeft: "0px",
      marginRight: "0px",
    },
    ['@media (max-width:600px)']: {
      padding: "0px",
    }
  }
}

export default ({isTests, }: any) => {
  const history = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 64,
  });

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = useCallback(({target}: any) => {
    const element = document.getElementsByClassName(target.innerText)[0];
    const yOffset = -72;
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    } else {
      window.scrollTo({top: 0,});
      if (linksByName[target.innerText]) {
        history(linksByName[target.innerText]);
      } else {
        history('/');
      }
    }
    handleCloseNavMenu();
  }, [history]);

  return (
    <AppBar sx={trigger ? {...styles.appBarSticky} : { ...styles.appBar }} position="static">
      <Container  maxWidth="xl" sx={{padding: "0px", ...styles.cont}}>
        <Toolbar disableGutters sx={{...styles.toolBar}}>
          <Button sx={{ marginRight: "25px",
            ['@media (max-width:500px)']: {
              marginRight: "1%",
              maxWidth: "100px"
            },}} onClick={() => history('/')}>
            <GFIcon />
          </Button>
          {/*<Box sx={{ flexGrow: 3, display: { xs: 'flex', md: 'none' }, boxShadow: "0px 0px" }}>*/}
          {/*  <IconButton*/}
          {/*    size="large"*/}
          {/*    aria-label="account of current user"*/}
          {/*    aria-controls="menu-appbar"*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={handleOpenNavMenu}*/}
          {/*    color="inherit"*/}
          {/*  >*/}
          {/*    <MenuIcon />*/}
          {/*  </IconButton>*/}
          {/*  <Menu*/}
          {/*    id="menu-appbar"*/}
          {/*    anchorEl={anchorElNav}*/}
          {/*    anchorOrigin={{*/}
          {/*      vertical: 'bottom',*/}
          {/*      horizontal: 'left',*/}
          {/*    }}*/}
          {/*    keepMounted*/}
          {/*    transformOrigin={{*/}
          {/*      vertical: 'top',*/}
          {/*      horizontal: 'left',*/}
          {/*    }}*/}
          {/*    open={Boolean(anchorElNav)}*/}
          {/*    onClose={handleCloseNavMenu}*/}
          {/*    sx={{*/}
          {/*      display: { xs: 'block', md: 'none' },*/}
          {/*      boxShadow: "0px 0px",*/}
          {/*      borderRadius: "30px"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {pagesWithLink.map((page) => (*/}
          {/*      <MenuItem key={page.name} onClick={handleMenuClick} href={page.link}>*/}
          {/*        <Typography textAlign="center" variant="subtitle1" color="textPrimary">{page.name}</Typography>*/}
          {/*      </MenuItem>*/}
          {/*    ))}*/}
          {/*  </Menu>*/}
          {/*</Box>*/}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
            <Button
              key={'Benefits'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              Benefits
            </Button>
            <Button
              key={'How it works'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              How it works
            </Button>
            <Button
              key={'About'}
              onClick={() => history('/about')}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              About
            </Button>
            {isTests ? <Button
              key={'Testimonials'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color='inherit'
            >
              Testimonials
            </Button> : null}
            <Button
              key={'FAQ'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              FAQ
            </Button>
          </Box>


          <Box sx={{ flexGrow: 0, minWidth: "150px", display: "flex", justifyContent: "center" }}>
            {/*<Tooltip title="Open settings">*/}
            {/*  <IconButton /*onClick={handleOpenUserMenu} sx={{ p: 0,*/}
            {/*    ['@media (max-width:500px)']: {*/}
            {/*      minWidth: "20px",*/}
            {/*      width: "20px"*/}
            {/*    },}}>*/}
            {/*    <Avatar alt="Remy Sharp" src="" />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
            {/*<Menu*/}
            {/*  sx={{ mt: '45px' }}*/}
            {/*  id="menu-appbar"*/}
            {/*  anchorEl={anchorElUser}*/}
            {/*  anchorOrigin={{*/}
            {/*    vertical: 'top',*/}
            {/*    horizontal: 'right',*/}
            {/*  }}*/}
            {/*  keepMounted*/}
            {/*  transformOrigin={{*/}
            {/*    vertical: 'top',*/}
            {/*    horizontal: 'right',*/}
            {/*  }}*/}
            {/*  open={Boolean(anchorElUser)}*/}
            {/*  onClose={handleCloseUserMenu}*/}
            {/*>*/}
            {/*  {settings.map((setting) => (*/}
            {/*    <MenuItem key={setting} onClick={handleCloseUserMenu}>*/}
            {/*      <Typography textAlign="center">{setting}</Typography>*/}
            {/*    </MenuItem>*/}
            {/*  ))}*/}
            {/*</Menu>*/}
            <StyledButton  sx={{...styles.signUp,
              ['@media (max-width:500px)']: {
                minWidth: "85px",
                width: "95px",
                fontSize: 17
              },
            }} text="Sign Up" onClick={() => history('/registration')}/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}